import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const MiniTieRatioChart = ({ inputData, tieRatio, tabChange }) => {
  const [activeLegend, setActiveLegend] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [hiddenLegends, setHiddenLegends] = useState([]);
  const [tieRatioPercentage, setTieRatioPercentage] = useState(null);
  const [legendTransparency, setLegendTransparency] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [tieRatioLegend, setTieRatioLegend] = useState("Total");

  const baseColors = ["75, 192, 192", "255, 206, 86", "255, 99, 132"];

  const labels = ["High", "Low", "Strict"];
  const totalSize = labels.reduce(
    (sum, label) => sum + inputData[label].length,
    0
  );
  const sizes = labels.map((label) => inputData[label].length);
  const percentages = sizes.map((size) => (size / totalSize) * 100);

  const groupData = (data) => {
    return data.reduce((acc, item) => {
      const [agent, value] = Object.entries(item)[0];
      if (!acc[value]) {
        acc[value] = { agents: [agent], count: 1 };
      } else {
        acc[value].agents.push(agent);
        acc[value].count += 1;
      }
      return acc;
    }, {});
  };

  const initialData = {
    labels,
    datasets: [
      {
        label: "Size",
        data: percentages,
        size: sizes,
        backgroundColor: labels.map((_, i) => `rgba(${baseColors[i]}, 0.2)`),
        borderColor: labels.map((_, i) => `rgba(${baseColors[i]}, 1)`),
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Ties Ratio Chart",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, dataIndex } = context;
            const size = dataset.size[dataIndex];
            const percentage = dataset.data[dataIndex];
            return `Size: ${size}, Percentage: ${percentage.toFixed(2)}%`;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 1)",
        opacity: 1,
        zIndex: 1,
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          generateLabels: () =>
            labels.map((label, i) => ({
              text: label,
              fillStyle: `rgba(${baseColors[i]}, ${
                legendTransparency[label] ? 1 : 0.2
              })`,
              strokeStyle: `rgba(${baseColors[i]}, 1)`,
              hidden: hiddenLegends.includes(label),
              lineWidth: 1,
            })),
        },
        onClick: (event, legendItem) => {
          const label = legendItem.text;
          const color = baseColors[labels.indexOf(label)];

          if (inputData[label].length === 0) {
            return;
          }

          setTieRatioLegend(label);

          if (activeLegend === label) {
            setActiveLegend(null);
            setChartData(initialData);
            setLegendTransparency({});
            setTieRatioLegend("Total");
          } else {
            setActiveLegend(label);
            const segmentData = inputData[label];
            const groupDataObj = groupData(segmentData);
            const subDataArray = Object.entries(groupDataObj).sort(
              ([keyA], [keyB]) => keyB - keyA
            );

            const subLabels = subDataArray.map(
              ([value, { agents }]) =>
                `Score: ${value}, Agents: ${agents.join(", ")}`
            );
            const subSizes = subDataArray.map(
              ([_, { agents }]) => agents.length
            );
            const subPercentages = subSizes.map(
              (size) => (size / segmentData.length) * 100
            );
            const step = 0.6 / (subLabels.length - 1 || 1);
            const backgroundColors = subLabels.map(
              (_, i) => `rgba(${color}, ${Math.max(0.2, 0.8 - i * step)})`
            );

            setChartData({
              labels: subLabels,
              datasets: [
                {
                  label: "Size",
                  data: subPercentages,
                  size: subSizes,
                  backgroundColor: backgroundColors,
                  borderColor: backgroundColors.map((color) =>
                    color.replace(/0\.\d+\)$/, "1)")
                  ),
                  borderWidth: 1,
                },
              ],
            });
            setLegendTransparency({
              [label]: true,
            });
          }
        },
      },
    },
  };

  useEffect(() => {
    setHiddenLegends(labels.filter((label) => inputData[label].length === 0));
    setChartData(initialData);
    setTieRatioLegend("Total");
    setActiveLegend(null);
    setLegendTransparency({});
  }, [inputData, tieRatio, tabChange]);

  //   useEffect(() => {
  //     setActiveLegend(null);
  //     setLegendTransparency({});
  //     setTieRatioLegend("Total");
  //     setHiddenLegends(labels.filter((label) => inputData[label].length === 0));
  //     setChartData(initialData);
  //     setTieRatioPercentage((tieRatio["Total"] * 100).toFixed(2));
  //   }, [tabChange]);

  if (!chartData) {
    return null;
  }

  return (
    <div
      style={{ height: "100%", width: "100%", position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Doughnut options={chartOptions} data={chartData} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "24px",
          fontWeight: "bold",
          zIndex: isHovered ? -1 : 0,
        }}
      >
        {/* {tieRatio[tieRatioLegend]
          ? (tieRatio[tieRatioLegend] * 100).toFixed(2)
          : 0}
        % */}
      </div>
    </div>
  );
};

export default MiniTieRatioChart;
