// Learn.js
import React, { useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import "./Learn.css";

const Learn = () => {
  // State to track the active section
  const [activeSection, setActiveSection] = useState("learn");

  // Function to handle menu item clicks
  const handleSelect = (selectedKey) => {
    setActiveSection(selectedKey);
  };

  // Define the content for each section
  const sectionContent = {
    learn: {
      title: "Welcome",
      text: "",
      subsections: [
        {
          id: "learn-sub1",
          title: "Learn",
          content: (
            <div>
              <p>
                The Learn page is designed to enrich your understanding of
                complex algorithms and statistical methods. Whether you're a
                student, researcher, or enthusiast, this page offers valuable
                insights into a range of topics, from basic concepts to advanced
                techniques.
              </p>
            </div>
          ),
        },
        {
          id: "learn-sub2",
          title: "Getting Started",
          content: (
            <div>
              <ul>
                <li>
                  <strong>Discover Algorithms:</strong> Click on any topic in
                  the side menu to learn about a specific algorithm or method.
                </li>
                <li>
                  <strong>Understand Related Usages:</strong> Within each topic,
                  the "Related Usages" section highlights where on our site you
                  can see the algorithm in action, helping you connect theory
                  with practice.
                </li>
                <li>
                  <strong>Deep Dive with References:</strong> Expand your
                  knowledge beyond our platform with the "References" section.
                  Here, you'll find links to external resources like academic
                  papers, Wikipedia articles, and sites offering visualizations
                  of algorithms, such as{" "}
                  <a
                    className="reference-link"
                    href="http://www.matchu.ai"
                    target="_blank"
                  >
                    MatchU
                  </a>
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    topic1: {
      title: "Borda Count",
      text: "",
      subsections: [
        {
          id: "topic1-sub1",
          title: "Related Usages",
          content: "Popularity Score; Mutual Like Heatmap",
        },
        {
          id: "topic1-sub2",
          title: "Algorithm Details",
          content: (
            <>
              <h5>Overview</h5>
              <p>
                The Borda Count method is a voting system used to rank
                alternatives based on the preferences of voters. It is often
                employed in situations with more than two options, such as
                elections with multiple alternatives or group decision-making
                scenarios. In the Borda Count method, voters rank alternatives
                in order of preference. With <em>N</em> alternatives, the
                top-ranked alternative receives <em>N-1</em> points, the second{" "}
                <em>N-2</em> points, down to the last-ranked alternative, which
                gets 0 points. This method handles ties and incomplete rankings
                using variations such as Averaged Borda or Pessimistic Borda.
              </p>

              <h5>Model</h5>
              <p>
                <strong>Standard Borda:</strong>
              </p>
              <ul>
                <li>
                  Let{" "}
                  <em>
                    P<sub>i</sub>
                  </em>{" "}
                  represent the points for the{" "}
                  <em>
                    i<sup>th</sup>
                  </em>{" "}
                  alternative. In this model, points are allocated to each
                  alternative based on the rankings given by each voter. The
                  formula is:
                </li>
                <li>
                  <em>
                    P<sub>i</sub> = Σ<sub>j=1</sub>
                    <sup>V</sup> (N - k<sub>ij</sub>)
                  </em>
                </li>
                <li>
                  where <em>V</em> is the total number of voters, <em>N</em> is
                  the total number of alternatives, and{" "}
                  <em>
                    k<sub>ij</sub>
                  </em>{" "}
                  is the position or rank given to alternative <em>i</em> by
                  voter <em>j</em> (with the top-ranked alternative receiving
                  the highest score and the lowest-ranked receiving the lowest
                  score).
                </li>
              </ul>

              <p>
                <strong>Averaged Borda:</strong>
              </p>
              <ul>
                <li>
                  This variation is applied to cases with incomplete preference
                  profiles. Alternatives not ranked (or truncated) are
                  considered as tied and placed at the end of the ranking.
                  Suppose <em>k</em> represents the top <em>k</em>-ranked
                  alternatives, where <em>k {"<"} N</em>. The truncated
                  alternatives are scored using the formula:
                </li>
                <li>
                  <em>
                    P<sub>truncated</sub> = ((N-k-1) + (N-k-2) + ... + 0) /
                    (N-k)
                  </em>
                </li>
                <li>
                  In this formula, <em>k</em> is the number of alternatives that
                  a voter has ranked, and <em>N</em> is the total number of
                  alternatives.
                </li>
              </ul>

              <p>
                <strong>Pessimistic Borda:</strong>
              </p>
              <ul>
                <li>
                  Used for incomplete preference profiles, this model assigns a
                  score of 0 to all alternatives that are not ranked (or
                  truncated) by a voter.
                </li>
              </ul>
            </>
          ),
        },
        {
          id: "topic1-sub3",
          title: "References",
          content: (
            <div>
              <a
                href="https://en.wikipedia.org/wiki/Borda_count"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Borda Count on Wikipedia
              </a>
              <br />
              <a
                href="https://www.sciencedirect.com/science/article/pii/S0304406820301208?ref=pdf_download&fr=RR-2&rr=8407e7481a1e0a85"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Borda class: An axiomatic study of the Borda rule on
                top-truncated preferences
              </a>
            </div>
          ),
        },
      ],
    },
    topic2: {
      title: "Kendall Tau Distance",
      text: "",
      subsections: [
        {
          id: "topic2-sub1",
          title: "Related Usages",
          content:
            "Similarity Chart; Clustering Dendrogram; Similarity Distance Heatmap",
        },
        {
          id: "topic2-sub2",
          title: "Algorithm Details",
          content: (
            <>
              <h5>Overview</h5>
              <p>
                The Kendall Tau distance is a statistical measure used to
                determine the dissimilarity between two ranking lists. It
                evaluates the number of pairwise disagreements between the
                lists, with special consideration for tied ranks and incomplete
                lists.
              </p>

              <h5>Handling Ties</h5>
              <ul>
                <li>
                  <strong>Definition of Ties:</strong> Occurs when two or more
                  items in a ranking list share the same position.
                </li>
                <li>
                  <strong>Impact on Calculation:</strong> A pair tied in one
                  ranking but not in the other is considered half discordant,
                  modifying the standard Kendall Tau distance calculation by
                  partially contributing to the total distance.
                </li>
              </ul>

              <h5>Handling Incomplete Lists</h5>
              <ul>
                <li>
                  <strong>Incomplete List Scenario:</strong> When one or both
                  ranking lists do not include all elements.
                </li>
                <li>
                  <strong>Adjustment in Calculation:</strong> Unranked elements
                  are treated as tied and added to the lists. Their discordance
                  with elements in the other list affects the calculation.
                </li>
              </ul>

              <h5>Calculation Method</h5>
              <ul>
                <li>
                  Compare each pair of elements in the lists for concordance,
                  discordance, or ties.
                </li>
                <li>
                  Compute the distance by summing:
                  <ul>
                    <li>0 for concordant pairs,</li>
                    <li>1 for discordant pairs,</li>
                    <li>0.5 for tied discordant pairs.</li>
                  </ul>
                </li>
              </ul>

              <h5>Examples</h5>
              <ul>
                <li>
                  <strong>
                    Example of SOC (Strict Orders, Complete Lists):
                  </strong>
                  <p>R1 = [3,1,2,4]; R2 = [2,3,4,1]</p>
                  <ul>
                    <li>Swap 1 – R2 becomes [3,2,4,1]</li>
                    <li>Swap 2 – R2 becomes [3,2,1,4]</li>
                    <li>
                      Swap 3 – R2 becomes [3,1,2,4], which is now equal to R1
                    </li>
                    <li>Kendall Tau distance = 3</li>
                  </ul>
                </li>

                <li>
                  <strong>
                    Example of TOC (Orders with Ties, Complete Lists):
                  </strong>
                  <p>R1 = [3,{"{1,2}"},4]; R2 = [2,3,4,1]</p>
                  <ul>
                    <li>
                      Since 1 and 2 are tied in R1, each discordant pair
                      involving these elements contributes 0.5 to the distance.
                    </li>
                    <li>Kendall Tau distance = 2.5</li>
                  </ul>
                </li>
              </ul>
            </>
          ),
        },
        {
          id: "topic2-sub3",
          title: "References",
          content: (
            <div>
              <a
                href="https://en.wikipedia.org/wiki/Kendall_tau_distance"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kendall Tau Distance on Wikipedia
              </a>
            </div>
          ),
        },
      ],
    },
    topic3: {
      title: "Louvain Algorithm",
      text: "",
      subsections: [
        { id: "topic3-sub1", title: "Related Usages", content: "Tie Network" },
        {
          id: "topic3-sub2",
          title: "Algorithm Details",
          content: (
            <>
              <h5>Overview</h5>
              <p>
                The Louvain Algorithm is a prominent method for detecting
                community structures in large networks. MatchXplain interprets
                ties within preference profiles as an adjacency matrix, which
                serves as input to the Louvain algorithm, clustering agents into
                communities based on the prevalence of these ties. This process
                organizes agents into clusters that reflect a similar level of
                desirability, indicative of their relative appeal within the
                network. For visual representation, MatchXplain illustrates
                agents as nodes and their connections as edges on a network
                graph. Agents within the same community are denoted by a
                consistent color, facilitating the immediate recognition of
                clustered preferences and emphasizing the collective
                desirability of each group.
              </p>

              <h5>Local Optimization</h5>
              <p>
                In the initial phase, each node in the network is considered as
                a separate community. Then, for each node, the algorithm
                evaluates the gain in modularity that would result from moving
                the node to the community of each of its neighbors. The node is
                then placed into the community for which this gain is maximized,
                provided the gain is positive. This process is applied
                repeatedly and iteratively for all nodes until no further
                improvement in modularity can be achieved, indicating a local
                optimum.
              </p>

              <h5>Community Aggregation</h5>
              <p>
                Once the local optimization phase reaches an equilibrium, the
                algorithm constructs a new network whose nodes are the
                communities found in the first phase. The weights of the edges
                between these new nodes are given by the sum of the weights of
                the edges between nodes in the respective communities.
                Self-loops are added to represent the internal structure of the
                communities.
              </p>

              <h5>Algorithm</h5>
              <p>
                The algorithm repeats these two phases iteratively, with each
                iteration potentially revealing hierarchically higher-level
                communities by optimizing modularity at a coarser scale. The
                process terminates when modularity cannot be increased further,
                indicating that the network's structure has been effectively
                partitioned into communities.
              </p>
              <p>
                Mathematically, modularity (<i>Q</i>) is defined as:
              </p>
              <p>
                <i>Q = </i>
                <span style={{ fontSize: "larger" }}>&frac12;</span>
                <i>m</i>
                <sup>-1</sup>
                <span style={{ fontSize: "larger" }}>&sum;</span>
                <sub>ij</sub>
                <span style={{ fontSize: "larger" }}>[</span>
                <i>
                  A<sub>ij</sub> -{" "}
                </i>
                <span style={{ fontSize: "larger" }}>&frac12;</span>
                <i>m</i>
                <sup>-1</sup>
                <i>
                  k<sub>i</sub> k<sub>j</sub>
                </i>
                <span style={{ fontSize: "larger" }}>]</span>
                &delta;(
                <i>
                  c<sub>i</sub>, c<sub>j</sub>
                </i>
                )
              </p>
              <p>
                where{" "}
                <i>
                  A<sub>ij</sub>
                </i>{" "}
                represents the weight of the edge between nodes <i>i</i> and{" "}
                <i>j</i>,{" "}
                <i>
                  k<sub>i</sub>
                </i>{" "}
                and{" "}
                <i>
                  k<sub>j</sub>
                </i>{" "}
                are the sum of the weights of the edges attached to nodes{" "}
                <i>i</i> and <i>j</i>, respectively, <i>m</i> is the sum of all
                of the edge weights in the network, &delta; is the Kronecker
                delta function which is 1 if <i>i</i> and <i>j</i> are in the
                same community and 0 otherwise, and{" "}
                <i>
                  c<sub>i</sub>
                </i>{" "}
                and{" "}
                <i>
                  c<sub>j</sub>
                </i>{" "}
                denote the communities of nodes <i>i</i> and <i>j</i>.
              </p>

              <p>
                By optimizing modularity, the Louvain Algorithm effectively
                identifies divisions in the network that maximize the density of
                edges within communities compared to edges between communities,
                revealing the network's modular structure. This algorithm is
                celebrated for its simplicity and its ability to handle large
                networks with millions of nodes and edges efficiently.
              </p>
            </>
          ),
        },
        {
          id: "topic3-sub3",
          title: "References",
          content: (
            <div>
              <a
                href="https://en.wikipedia.org/wiki/Louvain_method"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Louvain Algorithm on Wikipedia
              </a>
              <br />
              <a
                href="https://iopscience.iop.org/article/10.1088/1742-5468/2008/10/P10008/pdf"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fast unfolding of communities in large networks
              </a>
            </div>
          ),
        },
      ],
    },
    topic4: {
      title: "Tie Ratio Method",
      text: "",
      subsections: [
        { id: "topic4-sub1", title: "Related Usages", content: "Tie Ratio" },
        {
          id: "topic4-sub2",
          title: "Algorithm Details",
          content: (
            <>
              <h5>Tie Ratio Method</h5>
              <p>
                The platform further quantifies the extent of ties using the
                proprietary Tie Ratio Method. For each agent <i>i</i> in set{" "}
                <i>A</i>, the Individual Tie Score,{" "}
                <i>
                  T<sub>i</sub>
                </i>
                , is calculated as the total number of preferences involved in
                ties minus the number of unique ties in their preference list.
                Mathematically,{" "}
                <i>
                  T<sub>i</sub> = P<sub>ties, i</sub> - T<sub>i</sub>
                </i>
                , where{" "}
                <i>
                  P<sub>ties, i</sub>
                </i>{" "}
                is the count of preferences in ties for agent <i>i</i>, and{" "}
                <i>
                  T<sub>i</sub>
                </i>{" "}
                is the number of ties. The Total Tie Score is the summation of
                Individual Tie Scores across all agents,{" "}
                <i>
                  T<sub>total</sub> = Σ<sub>i=1</sub>
                  <sup>N</sup> T<sub>i</sub>
                </i>
                . The maximum Individual Tie Score is <i>N-1</i>, occurring when
                all agents in a preference list are tied, leading to a Maximum
                Total Tie Score of <i>(N-1) × N</i> for the entire set. The Tie
                Ratio, <i>TR</i>, is then defined as the ratio of the Total Tie
                Score to the Maximum Total Tie Score,{" "}
                <i>
                  TR = T<sub>total</sub> / ((N-1) × N)
                </i>
                , providing a normalized measure of the prevalence of ties
                within the preference profiles. The Tie Ratio will be then
                visualized through Doughnut Chart.
              </p>
            </>
          ),
        },
      ],
    },

    topic5: {
      title: "Deferred Acceptance",
      text: "",
      subsections: [
        {
          id: "topic5-sub1",
          title: "Related Usages",
          content: "Finding stable solutions that eliminate blocking pairs.",
        },
        {
          id: "topic5-sub2",
          title: "Algorithm Details",
          content: (
            <>
              <>
                <h5>The Stable Marriage Problem</h5>
                <p>
                  In an instance of the Stable Marriage Problem, there are two
                  sets of players, both of equal size. Each player in a set has
                  a strict and complete ordering of preferences over the players
                  in the other set (these preferences come from your uploaded
                  preference files). The goal of the Stable Marriage Problem is
                  to find a one-to-one matching (all players are matched to
                  exactly one player in the other set) so that no blocking pairs
                  exist. A blocking pair is what we call the scenario when two
                  unmatched players both prefer each other over their current
                  partner (these "rogue" players would be incentivized to match
                  with each other instead of their previous partners, causing
                  instability in our matching).
                </p>
                <h5>Algorithm</h5>
                <p>
                  In 1962, David Gale and Lloyd Shapley proposed an algorithm
                  (often referred to as deferred-acceptance) to find a solution
                  to the stable marriage problem, where players from one set
                  make a sequence proposals to the players in the other set;
                  these proposals can either be accepted or rejected according
                  to a player's tentative matching.
                </p>
                <h5>Handling Ties</h5>
                <p>
                  MatchXplain uses revised versions of the Deferred Acceptance
                  algorithm, published by Robert Irving in 1994. Irving's stable
                  matching algorithms allow for ties to be introduced into
                  preferences (players can indicate that they prefer two players
                  from the other set equally). As a result of this relaxation to
                  the problem, Irving introduced the three notions of stability:
                  <br />
                  <br />
                  <ul>
                    <li>
                      <strong>Weak Stability:</strong> This is equivalent to the
                      traditional notion of stability, where no two unmatched
                      players both strictly prefer each other over their current
                      partner.
                    </li>
                    <li>
                      <strong>Strong Stability:</strong> A matching where no two
                      unmatched players exist such that one strictly prefers the
                      other to their current partner and the other player is
                      indifferent betwen their current partner and their
                      "potential" partner. This is a stronger notion of
                      fairness, and it's not always guaranteed to be achieved.
                    </li>
                    <li>
                      <strong>Super Stability:</strong> A matching where no two
                      unmatched players are indifferent between each other and
                      their current partner. This is the strongest notion of
                      fairness, and it's even more difficult to achieve than
                      strong stability.
                    </li>
                  </ul>
                </p>
              </>
            </>
          ),
        },
        {
          id: "topic5-sub3",
          title: "References",
          content: (
            <div>
              <a
                href="https://www.jstor.org/stable/2312726"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                College admissions and the stability of marriage
              </a>
              <br />
              <a
                href="https://www.sciencedirect.com/science/article/pii/0166218X9200179P"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stable Marriage with Ties
              </a>
              <br />
              <a
                href="http://www.matchu.ai/GaleShapley"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visualization of the Deferred Acceptance Algorithm
              </a>
            </div>
          ),
        },
      ],
    },
    topic6: {
      title: "Top Trading Cycle",
      text: "",
      subsections: [
        {
          id: "topic6-sub1",
          title: "Related Usages",
          content:
            "Finding solutions that are efficient by eliminating envy cycles.",
        },
        {
          id: "topic6-sub2",
          title: "Algorithm Details",
          content: (
            <>
              <>
                <h5>Algorithm</h5>
                <p>
                  In 1974, Gale, Shapley, and Scarf published the Top Trading
                  Cycles (TTC) algorithm. The TTC algorithm is traditionally a
                  one-sided matching algorithm. This means that there is only
                  one set of players that have a strict and complete ordering of
                  preferences over a set of indivisible goods (the number of
                  players and goods should be equal), like houses. The TTC
                  algorithm guarantees to find an allocation which is
                  Pareto-efficient and strategy proof. The algorithm begins by
                  randomly assigning a good to each player. Next, each player
                  points to their most desired good, according to their
                  preferences. Each good "points" to its owner. This "pointing"
                  creates cycles which can be resolved by assigning each player
                  the good they are pointing to. Once this reassignment of goods
                  has been completed for a cycle, the players in that cycle take
                  their assigned goods and exit the market. this continues until
                  all cycles are resolved. The allocation is guaranteed to be
                  Pareto-efficient, which means that no player can get a
                  more-desired good without giving someone else a less-desired
                  good. This allocation is also strategy proof, which menas that
                  players can't strategically lie about their preferences to
                  improve their allocation.
                </p>
                <h5>Application to Two-Sided Matching</h5>
                <p>
                  MatchXplain implements the TTC algorithm by only considering
                  one set of players' preferences at a time, and temporarily
                  disregarding the other set of players' preferences. This
                  yields two potential solutions where each solution offers a
                  Pareto-efficient and strategy proof matching for either set of
                  agents.
                </p>
              </>
            </>
          ),
        },
        {
          id: "topic6-sub3",
          title: "References",
          content: (
            <div>
              <a
                href="https://www.semanticscholar.org/paper/On-cores-and-indivisibility-Shapley-Scarf/debfeb80510159d2bea2d0cf2509069766336f07"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Top Trading Cycles Paper
              </a>
              <br />
              <a
                href="http://www.matchu.ai/ttc"
                className="reference-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visualization of the Top Trading Cycle Algorithm
              </a>
            </div>
          ),
        },
      ],
    },
  };

  // Render subsections for the active section with # navigation
  const renderSubsections = (subsections) => {
    return subsections.map((sub) => (
      <React.Fragment key={sub.id}>
        <h3 className="subsection-title">
          <a
            href={`#${sub.id}`}
            style={{ color: "#0986c3", textDecoration: "none" }}
            id={sub.id}
          >
            {sub.title}
          </a>
        </h3>
        <p>{sub.content}</p>
      </React.Fragment>
    ));
  };

  return (
    <Container>
      <Row style={{justifyContent:'center', maxWidth:'90%'}}>
        <Col xs={12} md={2} id="sidebar-menu">
          {/* Sidebar Navigation */}
          <h4>MatchXplain</h4>
          <Nav
            variant="pills"
            className="flex-column"
            activeKey={activeSection}
            onSelect={handleSelect}
          >
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="learn">
                Welcome
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <h4>Explainable Data</h4>
          <Nav
            variant="pills"
            className="flex-column"
            activeKey={activeSection}
            onSelect={handleSelect}
          >
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="topic1">
                Borda Count
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="topic2">
                Kendall Tau Distance
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="topic3">
                Louvain Algorithm
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="topic4">
                Tie Ratio Method
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <h4>Robust Matchings</h4>
          <Nav
            variant="pills"
            className="flex-column"
            activeKey={activeSection}
            onSelect={handleSelect}
          >
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="topic5">
                Deferred Acceptance
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="submenu-title" eventKey="topic6">
                Top Trading Cycle
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={12} md={1} id="gap"></Col>
        <Col xs={12} md={8} id="main-content">
          {/* Main Content Area */}
          <h2
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
          >
            {sectionContent[activeSection].title}
          </h2>
          <p>{sectionContent[activeSection].text}</p>
          {/* Render subsections based on active section */}
          {renderSubsections(sectionContent[activeSection].subsections)}
        </Col>
      </Row>
      <Row>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Row>
    </Container>
  );
};

export default Learn;