import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Layout = () => {
  return (
    <>
      <NavBar />
      <Container fluid style={{maxWidth:'1300px'}}>
        <Row className="mt-3">
          <Col>
            <br />
            <Outlet />
          </Col>
        </Row>
      </Container>
      <div className="col-md-12"></div>
    </>
  );
};

export default Layout;
