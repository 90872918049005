import React from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';


const TieHeatMap = ({ data, type }) => {
    let divergeAt;
    let maxV;
    let minV = 0;
    switch (type) {
        case 'rank distribution':
            divergeAt = 0.4;
            maxV = data.length;
            break;
        case 'Tie Distribution':
            divergeAt = 0.4;
            maxV = data.length;
            break;
        case 'mutual like':
            divergeAt = 0.6;
            maxV = data.length - 1;
            break;
        case 'kendall tau':
            divergeAt = 0.6;
            maxV = (data.length * (data.length - 1)) / 2
            minV = 0
            break;
        default:
            divergeAt = 0.4;
    }
    console.log(data)
    const rankNumbers = data[0]?.data.map(({x}) => x.replace('Rank', ''));
    const showLabels = data.length <= 20;
    return (
      <div style={{ height: '500px', width: '100%' }}>
        <ResponsiveHeatMap
        data={data}
        // keys={data[0] ? Object.keys(data[0]) : []}
        margin={{ top: 60, right: 30, bottom: 60, left: 90 }} 
        valueFormat=".0f" 
        axisTop={
            {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Alternatives',
            legendPosition: 'middle',
            legendOffset: -20,
            tickValues: [rankNumbers[0], rankNumbers[rankNumbers.length - 1]],
          }
        }
        axisRight={null}
        axisBottom={null} 
        axisLeft={
                {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Alternatives',
            legendPosition: 'middle',
            legendOffset: -60, 
            }
        }
        colors={{
          type: 'diverging',
          scheme: 'blues',
          divergeAt: divergeAt,
          minValue: minV, 
          maxValue: maxV, 
        }}
        cellComponent="circle"
        emptyColor="#555555"
        cellOpacity={1}
        cellBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.4 ] ] }}
        labelTextColor={({ value, color }) => (value > divergeAt * maxV ? 'white' : 'black')}
        enableLabels={data.length <= 10} 
        legends={[
          {
            anchor: 'bottom',
            translateX: 0,
            translateY: 45,
            length: 400,
            thickness: 8,
            direction: 'row',
            tickPosition: 'after',
            tickSize: 3,
            tickSpacing: 4,
            tickOverlap: false,
            tickFormat: value => (Number.isInteger(value) ? value : ''),
            title: '# of Ties →',
            titleAlign: 'start',
            titleOffset: 4,
          },
        ]}
        hoverTarget='row'
      //   animate={true}
      //   motionConfig={{
      //     mass: 500,
      //     tension: 1,
      //     friction: 500,
      //     clamp: true,
      //     precision: 0.01,
      //     velocity: 0
      // }}
      />
      </div>
    );
  };



export default TieHeatMap;

