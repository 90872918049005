import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import hadi from "./Hosseini-Hadi.jpg";
import yubo from "./YuboJ.jpg";
import ronak from "./Ronak.jpeg";
 
function About() {
  const leftColumnStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "0px",
    borderRight: "2px solid #eee",
  };

  const rightColumnStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "60px",
    alignItems: "center",
    // justifyContent:'center',
    paddingTop: "1.5rem",
    gap: "5rem",
  };

  const principalStyle = {
    textAlign: "center",
    marginBottom: "30px",
  };

  const imageStylePrincipal = {
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    marginBottom: "20px",
  };

  const memberStyle = {
    display: "flex",
    marginBottom: "30px",
  };

  const imageStyleDeveloper = {
    width: "160px",
    height: "160px",
    borderRadius: "50%",
    marginRight: "15px",
  };

  const descriptionStyle = {
    textAlign: "left",
    fontSize: "1rem",
  };

  return (
    <Container style={{ backgroundColor: "#fff", color: "#333", maxWidthidth: "90%" }}>
      <Row className="my-5 justify-content-center">
        <Col md={11} className="d-flex flex-column justify-content-center">
          <h2
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
          >
            Our Vision
          </h2>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            MatchXplain is a not-for-profit platform with the grand vision of
            providing explainable AI solutions that involve decisions under
            (often ill-structured) preference data. Its goal is delivering a
            suite of provable recommendations to facilitate decision-making
            through explainable data, robust matchings, and versatile solutions.
          </p>
        </Col>
      </Row>

      <Row className="my-5 justify-content-center">
        <Col md={11} className="d-flex flex-column justify-content-center">
          <h2
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
          >
            The Team
          </h2>
        </Col>
      </Row>

      <Row>
        <Col md={6} style={leftColumnStyle}>
          <div style={principalStyle}>
            <img
              style={imageStylePrincipal}
              src={hadi}
              alt="Dr. Hadi Hosseini"
            />
            <h3>
              <a
                href="https://faculty.ist.psu.edu/hadi/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#0792d6", textDecoration: "none" }}
              >
                Dr. Hadi Hosseini
              </a>
            </h3>
            <p>PRINCIPAL INVESTIGATOR</p>
            <p
              style={{
                textAlign: "justify",
                padding: "50px",
                paddingTop: "0px",
              }}
            >
              Hadi Hosseini is an assistant professor in the College of
              Information Sciences and Technology at Penn State University. His
              research interest lies at the interface of artificial
              intelligence, computer science, and economics.
            </p>
          </div>
        </Col>

        <Col md={6} style={rightColumnStyle}>
          <div style={memberStyle}>
            <img style={imageStyleDeveloper} src={yubo} alt="Yubo Jing" />
            <div style={descriptionStyle}>
              <h3>
                <a
                  href="https://www.linkedin.com/in/yubojing/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0792d6", textDecoration: "none" }}
                >
                  Yubo Jing
                </a>
              </h3>
              <p>RESEARCHER & FULL-STACK DEVELOPER</p>
              <p>
                Yubo Jing is a Computer Science and Economics double major
                student at Penn State University.
              </p>
            </div>
          </div>

          <div style={memberStyle}>
            <img style={imageStyleDeveloper} src={ronak} alt="Ronak Singh" />
            <div style={descriptionStyle}>
              <h3>
                <a
                  href="https://www.linkedin.com/in/ronakjoshua/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0792d6", textDecoration: "none" }}
                >
                  Ronak Singh
                </a>
              </h3>
              <p>RESEARCHER & FULL-STACK DEVELOPER</p>
              <p>
                Ronak Singh is a computer science student at the Schreyer Honors
                College at Penn State University.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Container>
  );
}

export default About;
