// dataSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  descriptiveData: null,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setDescriptiveData: (state, action) => {
      state.descriptiveData = action.payload;
    },
    clearDescriptiveData: (state) => {
      state.descriptiveData = null;
    },
  },
});

export const { setDescriptiveData, clearDescriptiveData } = dataSlice.actions;
export default dataSlice.reducer;
