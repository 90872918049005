import React, { useState, useEffect, setShow }  from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router-dom';
import logo from './logo.svg';
import sign from './matchxplain_logo.png'
import './index.css';
import { BrowserRouter, Route, Link } from "react-router-dom";

function NavBar() {

  const location = useLocation();

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="True" 
    // style={{position:'fixed', marginTop:'-1rem', padding:'0px', width:'100%'}}
    >
      <Container>
        <Navbar.Brand href="/">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <img src={sign} className="App-logo" style={{height: '60px', padding:'6px'}} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={location.pathname} className="ms-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/learn">Learn</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar;