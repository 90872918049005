import React from 'react';
import { Bar } from 'react-chartjs-2'; 

const RankBarChart = ({ data }) => {

    const rankCountMap = new Map(Object.entries(data).map(([k, v]) => [parseInt(k), v]));
    const indexedData = Array.from(rankCountMap.entries()).sort((a, b) => a[0] - b[0]);

    const chartData = {
        labels: indexedData.map(([rank]) => rank), 
        datasets: [
            {
                label: 'Rank Distribution',
                data: indexedData.map(([rank, count]) => count),
                backgroundColor: 'rgb(237, 132, 40, 0.6)',
                borderColor: 'rgba(237, 132, 40, 0.2)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Rank'
                },
                ticks: {
                    stepSize: 1,
                    precision: 0
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Count'
                },
                ticks: {
                    stepSize: 1,
                    precision: 0
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.parsed.y;
                        return label;
                    }
                }
            },
            legend: {
                position: "bottom",
                display: false
            },
            title: { 
                display: true,
                text: 'Individual Rank Distribution'
            }
        }
    };

    return (
        <div style={{ height: '300px', width: '100%' }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default RankBarChart;
