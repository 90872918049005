// ReviewProfile.js
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap'; // Assuming you are using React-Bootstrap

const ReviewProfile = ({ showProfile, setShowProfile, codeContent }) => {
  return (
    <Modal show={showProfile} onHide={() => setShowProfile(false)} style={{ justifyContent: 'center', display: 'flex' }}>
      <Modal.Header closeButton>
        <Modal.Title>Preference Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Col md={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <pre
              style={{
                lineHeight: "1.6",
                backgroundColor: "#f5f5f5",
                padding: "20px",
                paddingRight: '40px',
                borderRadius: "10px",
                fontFamily: "'Courier New', monospace",
                textAlign: "left",
                maxHeight: '400px', // Set a max height with scroll
                overflowY: 'auto' // Add scroll if content is too long
              }}
            >
              <code>{codeContent}</code>
            </pre>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewProfile;
