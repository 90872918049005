import { React, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Carousel } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaArrowLeft } from "react-icons/fa";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Accordion from "react-bootstrap/Accordion";
import "font-awesome/css/font-awesome.min.css";
import TieRatioChart from "../TieRatioChart";
import IndifferenceHeatMap from "../IndifferenceHeatMap";
import IndifferenceNetwork from "../IndifferenceNetwork";
import "../Table.css";
import IndifferenceViolin from "../IndifferenceViolin";

function InfoTooltip(props) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip">{props.tooltipText}</Tooltip>}
    >
      <span>
        <Icon icon="fluent:info-28-regular" />
      </span>
    </OverlayTrigger>
  );
}

function InfoTableRow({ title, tooltipText, value, suffix }) {
  const stringValue = String(value);
  const hasParentheses = stringValue.includes("(");
  const [mainValue, iqrValue] = hasParentheses
    ? stringValue.split(" (")
    : [stringValue, null];
  const formattedIqrValue = iqrValue ? "(" + iqrValue : null;

  return (
    <tr>
      <td className="title-cell">
        {title}
        <InfoTooltip tooltipText={tooltipText} />
      </td>
      <td className="value-cell">
        {mainValue} {suffix ? <>({suffix})</> : null}
        {formattedIqrValue && (
          <div className="iqr-value">{formattedIqrValue}</div>
        )}
      </td>
    </tr>
  );
}

const IndifferenceAdvance = ({
  descriptiveData,
  onUpdateActiveLayer,
  currentLayer,
  handleUpload,
  chartType,
  setChartType,
}) => {
  //   const [chartType, setChartType] = useState("Ties Ratio");
  console.log(currentLayer.charAt(0).toUpperCase() + currentLayer.slice(1));

  const [showAgentInfo, setShowAgentInfo] = useState(false);
  const [agentNumberA, setAgentNumberA] = useState();
  const [agentNumberB, setAgentNumberB] = useState();

  const handleNumberChangeA = (event) => {
    setAgentNumberA(event.target.value);
  };
  const handleNumberChangeB = (event) => {
    setAgentNumberB(event.target.value);
  };

  const cardStyle = {
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "3px",
    transition: "0.3s",
  };
  const cardStyle1 = {
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1)",
    transition: "0.3s",
    borderRadius: "0px",
    border: "none",
  };

  const inputInfo = descriptiveData ? descriptiveData.input_info : null;
  const problemTypes =
    inputInfo && inputInfo.problem_type
      ? inputInfo.problem_type.split(",")
      : [];
  const preferenceTypes =
    inputInfo && inputInfo.preference_type
      ? inputInfo.preference_type.split(",")
      : [];

  const level1Info = descriptiveData ? descriptiveData.level1_info : null;
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const maxAgents = 12;

  const handleFormSubmitA = (event) => {
    console.log("Agent A:", agentNumberA);
    setShowAgentInfo(true);
  };
  const handleFormSubmitB = (event) => {
    event.preventDefault();

    console.log("Agent B:", agentNumberB);
    setShowAgentInfo(true);
  };

  const [activeTab, setActiveTab] = useState("proposer");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };
  const explanationMapping = {
    "Ties Ratio": [
      {
        title: "What is the Ties Ratio Chart?",
        body: (
          <>
            <strong>Scenario:</strong> Think of a school event where clubs (the
            current side) and students (the other side) are looking to match up.
            <br />
            <strong>Preference Ties:</strong> Clubs and students rank each
            other, possibly with equal preferences (ties).
            <br />
            <strong>Chart Analysis:</strong> Measures the extent of ties in
            preference profiles.
            <br />
            <strong>Ratio Range:</strong> From 0% (no ties) to 100% (all
            preferences are tied).
            <br />
            <strong>Goal:</strong> Quantifies the prevalence of ties in
            preferences, offering insight into decision-making patterns.
          </>
        ),
      },

      {
        title: "How to Interpret the Chart?",
        body: (
          <>
            <strong>Central Value:</strong> Indicates overall ties ratio in the
            whole preference profiles.
            <br />
            <strong>Ties Score Calculation:</strong> Each club has a ties score
            that determines classification into high, low, or strict groups.
            <br />
            <strong>High Group:</strong> Clubs with a higher ties score,
            indicating more ties in preferences.
            <br />
            <strong>Low Group:</strong> Clubs with a lower ties score, fewer
            ties in preferences.
            <br />
            <strong>Strict Group:</strong> Clubs with a 0 ties score, meaning no
            ties in their preferences.
          </>
        ),
      },
      {
        title: "How to Interact with the Chart?",
        body: (
          <>
            <strong>Hover:</strong> Reveals size and percentage for that group.
            <br />
            <strong>Legend Manipulation:</strong> Clicking a group legend to
            view detailed information about that group, the central value
            indicates the overall ties ratio in the preference profile of only
            that group.
          </>
        ),
      },
    ],
    "Ties Distribution": [
      {
        title: "What is Ties Distribution?",
        body: (
          <>
            Ties Distribution analyzes the frequency at which different
            alternatives are ranked equally by users.
            <br />
            <br />
            It counts the occurrences of ties across all user rankings, helping
            to understand how often users perceive certain alternatives as
            having equal value.
          </>
        ),
      },
      {
        title: "How to Interpret the Heatmap?",
        body: (
          <>
            The heatmap is a visual tool that displays the Ties Distribution:
            <br />
            <b>Darker shades of blue</b> indicate a higher frequency of ties,
            showing that users often view these alternatives as equal.
            <br />
            <b>Lighter shades of blue</b> signify fewer ties, revealing a
            preference for one alternative over the other.
          </>
        ),
      },
    ],

    "Ties Network": [
      {
        title: "What is Ties Network?",
        body: (
          <>
            The Ties Network is a visual representation of the Ties Distribution
            Matrix.
          </>
        ),
      },
      {
        title: "How to Interpret Ties Network?",
        body: (
          <>
            Interpreting the Ties Network involves understanding its structural
            elements:
            <br />
            <b>Nodes</b> represent the alternatives.
            <br />
            <b>Edges</b> signify the ties between alternatives, with thicker
            edges indicating a higher occurrence of ties.
            <br />
            <b>Communities</b> are clusters of closely tied alternatives,
            represented by different colors, showcasing groups of alternatives
            that are often ranked similarly.
          </>
        ),
      },
      {
        title: "How to Interact with the Chart?",
        body: (
          <>
            Engage with the Ties Network through various interactive features:
            <br />
            <b>Click a node</b>: Explore all connected nodes and edges to
            understand the ties around a particular alternative.
            <br />
            <b>Community View</b>: View the community in a circular layout to
            see how alternatives are grouped.
            <br />
            <b>Collapse View</b>: Condense the network to see each community as
            a super node, displaying the total degree both within the community
            and between different communities.
          </>
        ),
      },
    ],
  };

  const data = {
    name: "root",
    children: [
      {
        name: "child #1",
        children: [{ name: "grandchild #1" }, { name: "grandchild #2" }],
      },
      {
        name: "child #2",
        children: [
          {
            name: "grandchild #3",
            children: [
              { name: "great grandchild #1" },
              { name: "great grandchild #2" },
            ],
          },
          { name: "grandchild #4" },
        ],
      },
    ],
  };

  const [explanations, setExplanations] = useState(
    explanationMapping["Ties Ratio"]
  ); // Default to Borda Score explanations

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType);
    setExplanations(explanationMapping[newChartType]);
  };
  const [individualView2, setIndividualView2] = useState(false);

  function ordinalSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return (
        <>
          {i}
          <sup>st</sup>
        </>
      );
    }
    if (j == 2 && k != 12) {
      return (
        <>
          {i}
          <sup>nd</sup>
        </>
      );
    }
    if (j == 3 && k != 13) {
      return (
        <>
          {i}
          <sup>rd</sup>
        </>
      );
    }
    return (
      <>
        {i}
        <sup>th</sup>
      </>
    );
  }

  var rank =
    level1Info[activeTab].borda_score_stack.total
      .slice()
      .sort((a, b) => b - a)
      .indexOf(
        level1Info[activeTab].borda_score_stack.total[String(+agentNumberA - 1)]
      ) + 1;

  const [expanded, setExpanded] = useState(false);

  const handleBarClick = (altNumber) => {
    console.log("Bar Clicked in Parent Component:", `Alt ${altNumber}`);

    setAgentNumberA(altNumber + 1);

    handleFormSubmitA();
  };
  useEffect(() => {
    if (agentNumberA) {
      setIndividualView2(true);
    }
  }, [agentNumberA]);

  const [max_d, setMaxD] = useState(0.7);
  const [currentClusterDict, setCurrentClusterDict] = useState(null);

  useEffect(() => {
    if (
      level1Info &&
      level1Info[activeTab] &&
      level1Info[activeTab].hierarchical_clustering
    ) {
      const thresholds = level1Info[activeTab].hierarchical_clustering[0];
      const clusterDicts = level1Info[activeTab].hierarchical_clustering[1];

      const thresholdIndex = thresholds.findIndex((threshold, index) => {
        return (
          max_d >= threshold &&
          (index === thresholds.length - 1 || max_d < thresholds[index + 1])
        );
      });

      if (thresholdIndex !== -1) {
        setCurrentClusterDict(clusterDicts[thresholdIndex]);
      }
    }
    console.log(currentClusterDict);
  }, [level1Info, max_d, activeTab]);

  const handleThresholdChange = (event) => {
    setMaxD(parseFloat(event.target.value));
  };

  return (
    descriptiveData && (
      <Container fluid style={{ width: " 95%" }}>
        <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Col md={4}>
            <Button
              style={{ padding: "1rem", width: "15rem" }}
              onClick={() => onUpdateActiveLayer("layer1card")}
              variant="outline-secondary"
            >
              <FaArrowLeft /> Back to Overview
            </Button>
          </Col>
          <Col
            md={4}
            style={{
              display: "flex",
              marginLeft: "-20px",
              marginRight: "20px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Ties</h2>
          </Col>
          <Col md={4} style={{ textAlign: "end" }}>
            <Button
              onClick={handleUpload}
              variant="dark"
              style={{ padding: "1rem", width: "18rem" }}
            >
              Continue: Recommend Solutions <Icon icon="bytesize:arrow-right" />
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col md={2}>
            <h4>Methods: </h4>
          </Col>
          <Col md={10}>
            <div className="section-div">
              <Row>
                <Col md={4}>
                  <div
                    className={`section ${
                      chartType === "Ties Ratio" ? "active-section" : ""
                    }`}
                    onClick={() => handleChartTypeChange("Ties Ratio")}
                  >
                    <h4 className="sectionTitle">Ties Ratio</h4>
                    <p className="sectionContent">
                      An overview of how alternatives are indifferent about
                      their preferences
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div
                    className={`section ${
                      chartType === "Ties Network" ? "active-section" : ""
                    }`}
                    onClick={() => handleChartTypeChange("Ties Network")}
                  >
                    <h4 className="sectionTitle">Ties Network</h4>
                    <p className="sectionContent">
                      Visualizes the tied alternatives in a network format
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div
                    className={`section ${
                      chartType === "Ties Distribution" ? "active-section" : ""
                    }`}
                    onClick={() => handleChartTypeChange("Ties Distribution")}
                  >
                    <h4 className="sectionTitle">Ties Distribution</h4>
                    <p className="sectionContent">
                      Understand the distribution of how often alternatives are
                      ranked equally
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={1}>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabSelect}
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab
                eventKey="proposer"
                title={<span className="rotate-text">Side A</span>}
                className={activeTab === "proposer" ? "active" : ""}
              ></Tab>

              <Tab
                eventKey="proposee"
                title={<span className="rotate-text">Side B</span>}
                className={activeTab === "proposee" ? "active" : ""}
              ></Tab>
            </Tabs>
          </Col>

          <Col xs={12} md={11}>
            <div className="tab-wrapper">
              <Row>
                <Col xs={12} md={8}>
                  <Card className="mb-4" style={cardStyle1}>
                    <Card.Body>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Check
                          type="switch"
                          id="individual-view-switch2"
                          label="Individual View"
                          checked={individualView2} // Ensure the switch reflects the individualView state
                          onChange={(e) => setIndividualView2(e.target.checked)}
                          style={{ flex: "1", marginTop: "6px" }}
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            height: "50px",
                            alignItems: "center",
                          }}
                        >
                          <h3>{chartType}</h3>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            flexDirection: "row",
                            marginTop: "-10px",
                            visibility: "hidden",
                          }}
                        >
                          <Form
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Label
                              className="mr-2"
                              style={{ marginBottom: "0" }}
                            >
                              Threshold:
                            </Form.Label>
                            <Form.Range
                              value={max_d}
                              onChange={handleThresholdChange}
                              style={{
                                width: "50px",
                                display: "inline-block",
                                marginBottom: "0",
                              }}
                              min="0"
                              max="1"
                              step="0.05"
                            />
                            <span
                              className="ml-2"
                              style={{
                                marginBottom: "0",
                                width: "40px",
                                display: "inline-block",
                                textAlign: "center",
                              }}
                            >
                              {max_d}
                            </span>
                          </Form>
                        </div>
                      </div>
                      <Card.Text>
                        <div style={{ position: "relative", height: "520px" }}>
                          {level1Info &&
                            chartType === "Ties Ratio" &&
                            currentClusterDict && (
                              <TieRatioChart
                                inputData={
                                  activeTab === "proposer"
                                    ? level1Info.proposer.tie_ratio_group
                                    : level1Info.proposee.tie_ratio_group
                                }
                                tieRatio={
                                  activeTab === "proposer"
                                    ? level1Info.proposer.tie_ratio
                                    : level1Info.proposee.tie_ratio
                                }
                                tabChange={activeTab === "proposer" ? "A" : "B"}
                              />
                            )}
                          {/* <div>
                                                            <label>Threshold: </label>
                                                            <input type="number" step="0.01" min="0" max="1" value={max_d} onChange={handleThresholdChange} />
                                                        </div> */}

                          {chartType === "Ties Distribution" && (
                            <IndifferenceHeatMap
                              data={
                                activeTab === "proposer"
                                  ? level1Info.proposer.tie_distribution_heatmap
                                  : level1Info.proposee.tie_distribution_heatmap
                              }
                              currentTab={activeTab}
                              type="Tie Distribution"
                            />
                          )}
                          {chartType === "Ties Network" && (
                            <IndifferenceNetwork
                              matrix={
                                activeTab === "proposer"
                                  ? level1Info.proposer.tie_distribution_adj
                                  : level1Info.proposee.tie_distribution_adj
                              }
                              communities={
                                activeTab === "proposer"
                                  ? level1Info.proposer.tie_network_communities
                                  : level1Info.proposee.tie_network_communities
                              }
                              currentTab={activeTab}
                              onBarClick={handleBarClick}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card className="mb-4" style={cardStyle1}>
                    <Card.Body>
                      <Card.Text>
                        <>
                          <div
                            className="section-div"
                            style={{ position: "relative", height: "570px" }}
                          >
                            <Accordion
                              defaultActiveKey="0"
                              flush
                              className="custom-accordion"
                            >
                              {individualView2 ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      height: "50px",
                                      marginBottom: "1rem",
                                      marginTop: "2rem",
                                    }}
                                  >
                                    <Form
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                      onSupmit={handleFormSubmitA}
                                    >
                                      <Form.Group
                                        style={{
                                          justifyContent: "center",
                                          display: "flex",
                                          gap: "1rem",
                                          flexDirection: "row",
                                          textAlign: "center",
                                        }}
                                        controlId="formAgentNumber"
                                      >
                                        <Form.Label>
                                          <Card.Title>
                                            <h3>For Alternative:</h3>
                                          </Card.Title>
                                        </Form.Label>
                                        <Form.Control
                                          style={{
                                            maxWidth: "100px",
                                            textAlign: "center",
                                            justifyContent: "center",
                                          }}
                                          type="number"
                                          value={agentNumberA || ""}
                                          placeholder="Alt A"
                                          onChange={handleNumberChangeA}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                  <Card.Text>
                                    <div
                                      style={{
                                        position: "relative",
                                        height: "408px",
                                        margin: "1rem",
                                      }}
                                    >
                                      {level1Info &&
                                        level1Info[activeTab]
                                          .borda_score_stats && (
                                          <div>
                                            <table className="styled-table">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: "70%" }}>
                                                    Statistic
                                                  </th>
                                                  <th style={{ width: "30%" }}>
                                                    Value
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <InfoTableRow
                                                  title="Individual Score    "
                                                  tooltipText="Score"
                                                  value={
                                                    level1Info[activeTab]
                                                      .borda_score_stats[
                                                      "Truncated Size"
                                                    ][String(agentNumberA)] ||
                                                    "0"
                                                  }
                                                />
                                                <InfoTableRow
                                                  title="Borda Score    "
                                                  tooltipText="The Borda Score is the sum of the positions this alternative holds in all of the other side's alternatives' preference lists. A higher score means this alternative is generally preferred."
                                                  value={
                                                    level1Info[activeTab]
                                                      .borda_score_stack.total[
                                                      String(+agentNumberA - 1)
                                                    ] || "N/A"
                                                  }
                                                  suffix={ordinalSuffix(rank)}
                                                />
                                                <InfoTableRow
                                                  title="Average Ranked by B as    "
                                                  tooltipText="This is the average position that the other side's alternatives assigned to this alternative in their preference lists. A lower number means this alternative is generally ranked higher."
                                                  value={
                                                    level1Info[activeTab]
                                                      .borda_score_stats[
                                                      "Mean Rank"
                                                    ][String(agentNumberA)] ||
                                                    "N/A"
                                                  }
                                                />
                                                {expanded && (
                                                  <>
                                                    <InfoTableRow
                                                      title="Median Ranked by B as    "
                                                      tooltipText="This is the middle position that the other side's alternatives assigned to this alternative when all the rankings are lined up in numerical order."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Median Rank"
                                                        ][
                                                          String(agentNumberA)
                                                        ] || "N/A"
                                                      }
                                                    />
                                                    <InfoTableRow
                                                      title="Mode Ranked by B as    "
                                                      tooltipText="This is the position that this alternative most frequently holds in the other side's alternatives' preference lists."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Mode Rank"
                                                        ][String(agentNumberA)]
                                                          ? level1Info[
                                                              activeTab
                                                            ].borda_score_stats[
                                                              "Mode Rank"
                                                            ][
                                                              String(
                                                                agentNumberA
                                                              )
                                                            ]
                                                              .slice(0, 5)
                                                              .join(", ") +
                                                            (level1Info[
                                                              activeTab
                                                            ].borda_score_stats[
                                                              "Mode Rank"
                                                            ][
                                                              String(
                                                                agentNumberA
                                                              )
                                                            ].length > 5
                                                              ? ", ..."
                                                              : "")
                                                          : "N/A"
                                                      }
                                                    />
                                                    <InfoTableRow
                                                      title="Ranking Position Range    "
                                                      tooltipText="This shows the highest and lowest positions this alternative holds across all of the other side's alternatives' preference lists."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Range of Rank"
                                                        ][
                                                          String(agentNumberA)
                                                        ] || "N/A"
                                                      }
                                                    />
                                                    <InfoTableRow
                                                      title="Unranked Occurrences    "
                                                      tooltipText="This is the number of times this alternative was not ranked in the other side's alternatives' preference lists."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Truncated Size"
                                                        ][
                                                          String(agentNumberA)
                                                        ] || "0"
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </tbody>
                                            </table>
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <i
                                                className={`fa fa-chevron-${
                                                  expanded ? "up" : "down"
                                                }`}
                                                onClick={() =>
                                                  setExpanded(!expanded)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  margin: "0",
                                                }}
                                              />
                                            </div>
                                            {!expanded &&
                                              level1Info[activeTab]
                                                .tie_positions[
                                                agentNumberA - 1
                                              ] && (
                                                <div
                                                  style={{ margin: "-1rem" }}
                                                >
                                                  <IndifferenceViolin
                                                    data={
                                                      level1Info[activeTab]
                                                        .tie_positions[
                                                        agentNumberA - 1
                                                      ]
                                                    }
                                                  />
                                                </div>
                                              )}

                                            {/* <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', height: '50px', marginBottom: '3rem', marginTop: '3rem' }}>
                                                                        <Form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} onSubmit={handleFormSubmitB}>
                                                                            <Form.Group style={{ justifyContent: 'center', display: 'flex', gap: '1rem', flexDirection: 'row', textAlign: 'center' }} controlId="formAgentNumber">
                                                                            <Form.Label><Card.Title><h6>Tie Distribution Distance with:</h6></Card.Title></Form.Label>
                                                                            <Form.Control style={{ maxWidth: '100px', textAlign: 'center', justifyContent: 'center' }} type="number" value={agentNumberB || ''} placeholder="Alt B" onChange={handleNumberChangeB} />
                                                                            </Form.Group>
                                                                        </Form>
                                                                        </div> */}
                                          </div>
                                        )}
                                    </div>
                                  </Card.Text>
                                </>
                              ) : (
                                <>
                                  {explanations.map((explanation, index) => (
                                    <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                        {explanation.title}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {explanation.body}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ))}
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      width: "100%",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: "5px",
                                      padding: "5px",
                                    }}
                                  >
                                    <strong>For more insights:</strong>{" "}
                                    <a
                                      href="/learn#topic1-sub1"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "underline",
                                        color: "#007BFF",
                                      }}
                                    >
                                      Explore Learn
                                    </a>
                                  </div>
                                </>
                              )}
                            </Accordion>
                          </div>
                        </>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* View previous */}
        {/* <Row style={{marginTop:"2rem", marginBottom:'1rem'}}>
                    <Col style={{display:'flex', justifyContent:'start'}}>
                        <Button style={{padding:'1rem', width:'15rem'}} onClick={() => onUpdateActiveLayer('similarity')} variant="outline-secondary"> <Icon icon="bytesize:arrow-left" /> View previous: Similarity </Button>
                    </Col>
                </Row> */}
      </Container>
    )
  );
};

export default IndifferenceAdvance;
