import React, { useState, useEffect } from "react";
import FileUpload from "../components/FileUpload";
import Main from "../components/Main";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import { Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import "../index.css";
import "./Home.css";
import MatchXplainImage from "./matchxplainlogo.png";
import matchoutcome from "./matchoutcome.png";
import matchdata from "./matchdata.png";
import { Icon } from "@iconify/react";
import descriptiveJson from "./descriptiveupdate.json";
// import DescriptiveStatistic from "../components/DescriptiveStatistic";
import DescriptiveStatistic from "../components/newLayer1/DescriptiveStatisticMod";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setDescriptiveData } from "../components/dataSlice";

function Home() {
  // const [data, setData] = useState([{}]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const data = await API.get('matchxplainAPI', '/api')
  //     console.log(data)
  //   }

  //   getData()
  // }, [])

  const [demoBool, setDemoBool] = useState(true);

  // const codeContent = `
  //   # PREFERENCE TYPE: toc
  //   # MATCH TYPE: two-sided
  //   # MATCH TYPE: one-one
  //   10,10
  //   1: 1,5,6,3,2,4,7,{8,9,10}
  //   2: 7,2,10,1,3,4,5,6,8,9
  //   3: 3,2,1,4,5,6,7,8,9,10
  //   4: 1,8,4,2,3,5,{6,7,9,10}
  //   5: 3,2,1,4,5,6,7,8,9,10
  //   6: 1,8,4,2,3,5,{6,7,9,10}
  //   7: {3,2,1,4},5,6,7,8,9,10
  //   8: {3,2,1,4},5,6,{7,8,9,10}
  //   9: 1,8,4,2,3,5,6,{7,9,10}
  //   10: 2,3,4,7,8,1,5,6,9,10
  //   1: 1,5,6,3,2,4,7,8,9,10
  //   2: 7,2,10,1,3,4,5,6,{8,9}
  //   3: 3,2,1,4,5,6,7,8,9,10
  //   4: 1,8,4,2,3,5,6,7,9,10
  //   5: 3,2,1,4,5,6,7,{8,9,10}
  //   6: 1,8,4,2,3,5,6,7,9,10
  //   7: {3,2,1,4},5,6,7,8,9,10
  //   8: {3,2,1,4},5,6,{7,8,9,10}
  //   9: 9,5,6,2,3,4,7,8,1,10
  //   10: 1,8,4,2,3,5,6,7,9,10
  //   `;

  const codeContent = `  # PREFERENCE TYPE: toc
  # MATCH TYPE: two-sided
  # MATCH TYPE: one-one
  5,5
  1: 1,5,3,2,4
  2: 1,3,4,5,2
  3: 3,2,1,4,5
  4: 1,4,2,3,5
  5: 3,2,1,4,5

  1: 3,2,1,4,5
  2: 2,1,3,4,5
  3: 3,2,1,4,5
  4: 1,4,2,3,5
  5: 3,2,1,4,5`;

  const navigate = useNavigate();

  const [restartAnimation, setRestartAnimation] = useState(false);

  // State to track the section being hovered over
  const [hoveredSection, setHoveredSection] = useState("explainable");
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    const newSection = selectedIndex === 0 ? "explainable" : "robust";
    setHoveredSection(newSection);
  };

  const handleLogoClick = () => {
    setRestartAnimation(true);
    setTimeout(() => setRestartAnimation(false), 50);
  };

  const [showProfile, setShowProfile] = useState(false);

  const handleProfile = () => {
    setShowProfile(true);
  };

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    padding: "15px",
    width: "15rem",
    fontWeight: "bold",
  };
  const dispatch = useDispatch();

  // const [descriptiveData, setDescriptiveData] = useState(descriptiveJson);
  const [showDescriptiveStatistic, setShowDescriptiveStatistic] =
    useState(false);

  const descriptiveData = useSelector((state) => state.data.descriptiveData);
  // console.log(JSON.stringify(descriptiveData));
  const [solutions, setSolutions] = useState(false);
  const handleSolutions = () => {
    dispatch(setDescriptiveData(descriptiveJson));
    setSolutions(true);
  };
  const handleCloseSuccess = () => setSolutions(false);
  const [contentId, setContentId] = useState(null);
  function handleUpload() {
    navigate("/new-ms", {
      state: { contentId: contentId, demoBool: demoBool },
    });
  }

  return (
    <Container fluid>
      <Row style={{ textAlign: "center", justifyContent: "center" }}>
        <Col className="backgroundImageStyle">
          <Stack className="col-md-12 mx-auto">
            <p className={`rotatingText ${restartAnimation ? "restart" : ""}`}>
              <span className="rotatingText-adjective">
                <span style={{ color: "#0792d6" }}>Explainable</span> Data
              </span>
              <span className="rotatingText-adjective">
                <span style={{ color: "#0792d6" }}>Robust</span> Matchings
              </span>
              <span className="rotatingText-adjective">
                <span style={{ color: "#0792d6" }}>Versatile</span> Solutions
              </span>
              <span
                className="rotatingText-adjective"
                onClick={handleLogoClick}
              >
                <img src={MatchXplainImage} alt="MatchXplain" />
              </span>
            </p>
            <div style={{ height: "min(calc(3vw + 3em),150px)" }}></div>
            <h1
              style={{
                fontSize: "25px",
                color: "rgb(120,120,120)",
                textAlign: "center",
                marginTop: "16rem",
                marginBottom: "2rem",
              }}
            >
              Understanding Matchings, Simplifying Choices.
            </h1>
          </Stack>

          <Row className="col-md-12 mx-auto">
            {/* <p style={{ textAlign: "center", fontSize: 20 }}>
            Quickly upload your preference profile in the{" "}
            <a href="/file-format">correct format</a> and let MatchXplain
            reveal the best matchings.
          </p> */}
            {/* <p style={{ textAlign: "center", fontSize: 20 }}>
          Start Matchings by Uploading Your Preference Profile
          </p> */}
            {/* <Col>
            Start Matching By 
          </Col> */}
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <FileUpload />
            </div>
          </Row>
        </Col>
      </Row>
      <br />

      <Row
        className="mx-auto p-2"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        <Col md={6} className="mx-auto">
          <Stack gap={2} className="col-md-12 mx-auto">
            <h1 style={{ fontSize: 50, textAlign: "center" }}>Try Our Demo</h1>
            <p>
              <strong>
                Explore our interactive demo guide to get started!
              </strong>
            </p>
          </Stack>
        </Col>
      </Row>
      <Row
        className="mx-auto p-2 mb-2"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        <Col md={3} className="mx-auto"></Col>
        <Col md={3} className="mx-auto">
          <Button
            onClick={handleProfile}
            variant="light"
            style={{ padding: "15px", width: "15rem", fontWeight: "bold" }}
          >
            View Sample Profile
          </Button>
          <Modal
            size="lg"
            show={showProfile}
            onHide={() => setShowProfile(false)}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sample Preference Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ width: "100%" }}>
                <Col
                  md={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <pre
                    style={{
                      lineHeight: "1.6",
                      backgroundColor: "#f5f5f5",
                      padding: "20px",
                      paddingRight: "40px",
                      borderRadius: "10px",
                      fontFamily: "'Courier New', monospace",
                      textAlign: "left",
                      maxHeight: "400px", // Set a max height with scroll
                      overflowY: "auto", // Add scroll if content is too long
                    }}
                  >
                    <code>{codeContent}</code>
                  </pre>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <p>
                    This is a sample preference profile. For more detailed
                    information on the file format and how to create your own
                    profile, please click "View File Format". To check out the
                    sample solutions, click "View Sample Solutions".
                  </p>
                  <Row>
                    <Col md={6}>
                      <Button
                        variant="info"
                        href="/file-format"
                        style={{ alignSelf: "center", marginTop: "10px" }}
                        target="_blank"
                      >
                        View File Formats
                      </Button>
                    </Col>
                    <Col md={7}>
                      <Button
                        variant="outline-dark"
                        style={{ alignSelf: "center", marginTop: "10px" }}
                        onClick={handleSolutions}
                        handleUpload={handleUpload}
                      >
                        View Sample Solutions
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Col>
        <Col md={3} className="mx-auto">
          <Button
            variant="outline-dark"
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleSolutions}
          >
            View Sample Solutions
          </Button>
        </Col>
        <Col md={3} className="mx-auto"></Col>
      </Row>
      <br />

      <Row
        className="mx-auto p-2 mb-2"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        <Col
          md={5}
          onMouseEnter={() => {
            setHoveredSection("explainable");
            setIndex(0);
          }}
        >
          <div
            className={`main-section ${
              hoveredSection === "explainable" ? "main-section-hovered" : ""
            }`}
            style={{ height: "140px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Icon
                icon="carbon:data-analytics"
                width="32"
                height="32"
                color="#ff582e"
              />
              <h4
                className="sectionTitle"
                style={{ marginBottom: "0px", marginLeft: "10px" }}
              >
                Explainable Data
              </h4>
            </div>
            <p
              className="sectionContent"
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                textAlign: "left",
              }}
            >
              Uncover preferences with our insightful analytical engine.
            </p>
            {hoveredSection === "explainable" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              >
                <p
                  style={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: "left",
                    color: "#ff582e",
                  }}
                >
                  Learn more{" "}
                </p>
                <Icon
                  icon="ri:arrow-right-s-fill"
                  width="16"
                  height="16"
                  color="#ff582e"
                />
              </div>
            )}
          </div>
        </Col>
        <Col
          md={5}
          onMouseEnter={() => {
            setHoveredSection("robust");
            setIndex(1);
          }}
        >
          <div
            className={`main-section ${
              hoveredSection === "robust" ? "main-section-hovered" : ""
            }`}
            style={{ height: "140px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <Icon
                icon="vaadin:connect-o"
                width="30"
                height="30"
                color="#0792d6"
              />
              <h4
                className="sectionTitle"
                style={{ marginBottom: "0px", marginLeft: "10px" }}
              >
                Robust Matchings
              </h4>
            </div>
            <p
              className="sectionContent"
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                textAlign: "left",
              }}
            >
              Informed decisions through our transparent matching engine.
            </p>
            {hoveredSection === "robust" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              >
                <p
                  style={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: "left",
                    color: "#0792d6",
                  }}
                >
                  Learn more{" "}
                </p>
                <Icon
                  icon="ri:arrow-right-s-fill"
                  width="16"
                  height="16"
                  color="#0792d6"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Row
        className="mx-auto p-2"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        <Col md={10}>
          <div className="carousel-container">
            <Carousel
              fade
              activeIndex={index}
              onSelect={handleSelect}
              pause={"hover"}
            >
              <Carousel.Item>
                <div className="carousel-image-container">
                  <img
                    src={matchdata}
                    className="carousel-image img-fluid mx-auto"
                    alt="Explainable Data"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="carousel-image-container">
                  <img
                    src={matchoutcome}
                    className="carousel-image img-fluid mx-auto"
                    alt="Robust Matchings"
                  />
                </div>
              </Carousel.Item>
              {/* Add more Carousel.Item as needed */}
            </Carousel>
          </div>
        </Col>
      </Row>

      <br />
      <br />
      {/* <Row
        className="mx-auto p-2"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        <Col md={6} className="mx-auto">
          <Stack gap={2} className="col-md-12 mx-auto">
            <h1 style={{ fontSize: 50, textAlign: "center" }}>
              First Time User?
            </h1>
            <p>
              Explore our interactive demo guide to begin your adventure. Click
              any of the buttons below to get started!
            </p>
          </Stack>
        </Col>
      </Row>
      <Row
        className="mx-auto p-2 mb-2"
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        <Col md={3} className="mx-auto"></Col>
        <Col md={3} className="mx-auto">
          <Button
            onClick={handleProfile}
            variant="light"
            style={{ padding: "15px", width: "15rem", fontWeight: "bold" }}
          >
            View Sample Profile
          </Button>
          <Modal
            size="lg"
            show={showProfile}
            onHide={() => setShowProfile(false)}
            style={{ justifyContent: "center", display: "flex" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sample Preference Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ width: "100%" }}>
                <Col
                  md={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <pre
                    style={{
                      lineHeight: "1.6",
                      backgroundColor: "#f5f5f5",
                      padding: "20px",
                      paddingRight: "40px",
                      borderRadius: "10px",
                      fontFamily: "'Courier New', monospace",
                      textAlign: "left",
                      maxHeight: "400px", // Set a max height with scroll
                      overflowY: "auto", // Add scroll if content is too long
                    }}
                  >
                    <code>{codeContent}</code>
                  </pre>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <p>
                    This is a sample preference profile. For more detailed
                    information on the file format and how to create your own
                    profile, please click "View File Format". To check out the
                    sample solutions, click "View Sample Solutions".
                  </p>
                  <Row>
                    <Col md={6}>
                      <Button
                        variant="info"
                        href="/file-format"
                        style={{ alignSelf: "center", marginTop: "10px" }}
                        target="_blank"
                      >
                        View File Formats
                      </Button>
                    </Col>
                    <Col md={7}>
                      <Button
                        variant="outline-dark"
                        style={{ alignSelf: "center", marginTop: "10px" }}
                        onClick={handleSolutions}
                        handleUpload={handleUpload}
                      >
                        View Sample Solutions
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Col>
        <Col md={3} className="mx-auto">
          <Button
            variant="outline-dark"
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleSolutions}
          >
            View Sample Solutions
          </Button>
        </Col>
        <Col md={3} className="mx-auto"></Col>
      </Row> */}
      {descriptiveData && (
        <DescriptiveStatistic
          show={solutions}
          descriptiveData={descriptiveData}
          onHide={handleCloseSuccess}
          handleUpload={handleUpload}
        />
      )}
      <br />
      <br />
      <br />
      <br />
    </Container>
    // <div>
    //   <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h3>
    //   {/* <Stack gap={3}>
    //     <h1>Home Page</h1>
    //     {(typeof data.members === 'undefined') ? (
    //       <p>Loading...</p>
    //     ):(
    //       data.members.map((member, i) => (
    //         <React.Fragment>
    //           <div className='bg-light border'>
    //             <p key={i} className="body">{member}</p>
    //           </div>
    //         </React.Fragment>
    //       ))
    //     )}
    //   </Stack> */}
    //   <FileUpload/>
    // </div>
  );
}

export default Home;
