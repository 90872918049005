import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

import { API } from 'aws-amplify';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);


function ChartJSRadar() {
    const location = useLocation()
    const [compData, SetCompData] = useState([{}])
    const [compData2, SetCompData2] = useState([{}])
    const matchSummary = location.state.data
    console.log(matchSummary.matches[0])

    useEffect(() => {
        const fetchData = async () => {
            try {
                // First API call with matchSummary.matches[0]
                const apiData = await API.post('matchxplainAPI', '/api/match-detail', {
                    body: { 'data': matchSummary.matches[0] }
                });
                SetCompData(apiData);
                console.log(apiData.properties.envy.numberViolators);
                console.log(matchSummary.matches[0].metaData[1]);

                // Second API call with matchSummary.matches[1]
                const apiData2 = await API.post('matchxplainAPI', '/api/match-detail', {
                    body: { 'data': matchSummary.matches[1] }
                });
                SetCompData2(apiData2);
                console.log(apiData2.properties);
                console.log(matchSummary.matches[1].metaData[1]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (matchSummary && matchSummary.matches && matchSummary.matches.length >= 2) {
            fetchData();
        } else {
            console.log('Insufficient match data available');
        }
    }, [matchSummary, SetCompData, SetCompData2]);


    const match1PO = (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2] - compData?.properties?.po.agentsInvolved.length) / (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2]) * 100;
    const match1UA = (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2] - compData?.properties?.envy.numberViolators) / (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2]) * 100;
    const match1SP = (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2] - compData?.properties?.blockingpairs.agentsInvolved) / (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2]) * 100;
    const match1MA = (2 * matchSummary.matches[0].matching.men.length) / (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2]) * 100;
    const match1FP = (compData?.properties?.firstPreferenceMatch) / (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2]) * 100;

    const match1Data = [+match1PO.toFixed(2), +match1UA.toFixed(2), +match1SP.toFixed(2), +match1MA.toFixed(2), +match1FP.toFixed(2)];

    const match2PO = (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2] - compData2?.properties?.po.agentsInvolved.length) / (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2]) * 100;
    const match2UA = (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2] - compData2?.properties?.envy.numberViolators) / (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2]) * 100;
    const match2SP = (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2] - compData2?.properties?.blockingpairs.agentsInvolved) / (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2]) * 100;
    const match2MA = (2 * matchSummary.matches[1].matching.men.length) / (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2]) * 100;
    const match2FP = (compData2?.properties?.firstPreferenceMatch) / (matchSummary.matches[1].metaData[1] + matchSummary.matches[1].metaData[2]) * 100;

    const match2Data = [+match2PO.toFixed(2), +match2UA.toFixed(2), +match2SP.toFixed(2), +match2MA.toFixed(2), +match2FP.toFixed(2)];

    const data = {
        labels: ['% of Pareto Optimal Agents', '% of Unvenvious Agents', '% of Agents in Stable Pairs', '% of Matched Agents', '% of Agents Matched With First Preference'],
        datasets: [
            {
                label: 'Deferred Acceptance',
                data: match1Data,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            {
                label: 'Top Trading Cycle',
                data: match2Data,
                backgroundColor: 'rgba(0, 99, 132, 0.2)',
                borderColor: 'rgba(0, 99, 132, 1)',
                borderWidth: 1,
            }
        ],
    };

    const options = {
        animation: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
                align: "center",
                labels: {
                    font: {
                        size: 15
                    }
                }
            }
        },
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: 100,
                pointLabels: {
                    font: {
                        size: 15
                    }
                }
            }
        }
    }

    return (
        <div style={{ "height": "600px", "width": "100%" }}>
            {((typeof compData?.properties !== 'undefined') && typeof compData2?.properties !== 'undefined') ? (
                <Radar data={data} options={options} />
            ) : (
                <p>Loading...</p>
            )}
        </div>

    )
}

export default ChartJSRadar;