import React, { useEffect } from 'react';
 
const savg = 'https://matchsvg.s3.amazonaws.com/a_new.svg'
const sbvg = 'https://matchsvg.s3.amazonaws.com/b_new.svg'

function Dendrogram({side, agents, s3_url, onDendrogramClick}) {
    useEffect(() => {
        const urlToFetch = s3_url || (side === 'a' ? savg : sbvg);
        fetch(urlToFetch)
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const svg = parser.parseFromString(data, 'image/svg+xml').documentElement;
                svg.setAttribute('width', '100%');
                svg.setAttribute('height', '500px');
                
                const dendrogramDiv = document.getElementById('dendrogram');
                dendrogramDiv.innerHTML = '';  // Clear the contents of the div
                dendrogramDiv.appendChild(svg);

                var gElements = document.querySelectorAll('#dendrogram g[id^="xtick"]');
                gElements.forEach(element => {
                    var bbox = element.getBBox();
                    var rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
                    rect.setAttribute('x', bbox.x - 10);
                    rect.setAttribute('y', bbox.y - 10);
                    rect.setAttribute('width', bbox.width + 20);
                    rect.setAttribute('height', bbox.height + 20);
                    rect.setAttribute('fill', 'transparent');
                    rect.style.cursor = 'pointer';
                    element.insertBefore(rect, element.firstChild);
                    element.addEventListener('mouseover', event => {
                        var id = event.currentTarget.id;
                        var index = parseInt(id.split('_')[1]) - 1;
                        var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
                        text.setAttribute('x', bbox.x + bbox.width / 2);
                        text.setAttribute('y', bbox.y + bbox.height + 20);
                        text.setAttribute('font-size', 18);
                        text.setAttribute('fill', 'black');
                        text.setAttribute('text-anchor', 'middle');
                        text.textContent = "Alts: " + agents[index];
                        
                        element.appendChild(text);
                    });
                    element.addEventListener('mouseout', event => {
                        var textElements = element.getElementsByTagName('text');
                        Array.from(textElements).forEach(textElement => {
                            element.removeChild(textElement);
                        });
                    });
                    element.addEventListener('click', event => {
                        var id = event.currentTarget.id;
                        var index = parseInt(id.split('_')[1]) - 1;
                        var firstElement = JSON.parse(agents[index])[0];
                        onDendrogramClick && onDendrogramClick(firstElement);
                    });
                });

            });
    }, [side, agents]);

    return (
        <div id="dendrogram" style={{ maxWidth: '100%' }}></div>
    );
}

export default Dendrogram;
