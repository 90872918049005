import { React, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaArrowLeft } from "react-icons/fa";
import KendallTauBar from "../KendallTauBar";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Icon } from "@iconify/react";
import ScatterChart from "../ScatterChart";
import KtHeatMap from "../KtHeatMap";
import Accordion from "react-bootstrap/Accordion";
import "font-awesome/css/font-awesome.min.css";
import Dendrogram from "../Dendrogram";
import "../Table.css";

function InfoTooltip(props) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip">{props.tooltipText}</Tooltip>}
    >
      <span>
        <Icon icon="fluent:info-28-regular" />
      </span>
    </OverlayTrigger>
  );
}

function InfoTableRow({ title, tooltipText, value, suffix }) {
  const stringValue = String(value);
  const hasParentheses = stringValue.includes("(");
  const [mainValue, iqrValue] = hasParentheses
    ? stringValue.split(" (")
    : [stringValue, null];
  const formattedIqrValue = iqrValue ? "(" + iqrValue : null;

  return (
    <tr>
      <td className="title-cell">
        {title}
        <InfoTooltip tooltipText={tooltipText} />
      </td>
      <td className="value-cell">
        {mainValue} {suffix ? <>({suffix})</> : null}
        {formattedIqrValue && (
          <div className="iqr-value">{formattedIqrValue}</div>
        )}
      </td>
    </tr>
  );
}

const DiversityAdvance = ({
  descriptiveData,
  onUpdateActiveLayer,
  currentLayer,
  handleUpload,
  chartType,
  setChartType,
}) => {
  // const [chartType, setChartType] = useState("Similarity Chart");

  const [showAgentInfo, setShowAgentInfo] = useState(false);
  const [agentNumberA, setAgentNumberA] = useState(); // Default value of 1
  const [agentNumberB, setAgentNumberB] = useState(); // Default value of 2

  const handleNumberChangeA = (event) => {
    setAgentNumberA(event.target.value);
  };
  const handleNumberChangeB = (event) => {
    setAgentNumberB(event.target.value);
  };

  const cardStyle = {
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "3px",
    transition: "0.3s",
  };
  const cardStyle1 = {
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1)",
    transition: "0.3s",
    borderRadius: "0px",
    border: "none",
  };

  // Input information: Two-Sides, One-to-One, TOC
  const inputInfo = descriptiveData ? descriptiveData.input_info : null;
  const problemTypes =
    inputInfo && inputInfo.problem_type
      ? inputInfo.problem_type.split(",")
      : [];
  const preferenceTypes =
    inputInfo && inputInfo.preference_type
      ? inputInfo.preference_type.split(",")
      : [];

  const level1Info = descriptiveData ? descriptiveData.level1_info : null;
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const maxAgents = 12;

  const handleFormSubmitA = (event) => {
    console.log("Agent A:", agentNumberA);
    setShowAgentInfo(true);
  };
  const handleFormSubmitB = (event) => {
    event.preventDefault();

    console.log("Agent B:", agentNumberB);
    setShowAgentInfo(true);
  };

  const [activeTab, setActiveTab] = useState("proposer");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };
  const explanationMapping = {
    "Similarity Chart": [
      {
        title: "What is the Similarity Chart?",
        body: (
          <>
            <strong>Scenario:</strong> Think of a school event where clubs (the
            current side) and students (the other side) are looking to match up.
            <br />
            <strong>Clubs' Preferences:</strong> Each club ranks students
            according to preference.
            <br />
            <strong>Similarity Insight:</strong> The chart captures and
            visualizes preference similarities among clubs.
            <br />
            <strong>Chart Function:</strong> Provides a 2-D visual
            representation of club preference correlations.
            <br />
            <strong>Goal:</strong> Enables easy comparison of club preferences
            to identify similar patterns and potential grouping tendencies.
          </>
        ),
      },

      {
        title: "How to Interpret the Chart?",
        body: (
          <>
            <strong>Dot Representation:</strong> Each dot signifies a club's
            preference profile.
            <br />
            <strong>Distance and Similarity:</strong> Indicates preference
            similarity; closer dots mean higher similarity.
            <br />
            <strong>Color Coding:</strong> Differentiates clusters of clubs with
            similar ranking patterns.
            <br />
            <strong>Purpose of Clusters:</strong> Assists in identifying clubs
            with similar preferences for students.
          </>
        ),
      },
      {
        title: "How to Interact with the Chart?",
        body: (
          <>
            <strong>Hover:</strong> Reveals the clubs that represent the dots.
            <br />
            <strong>Click:</strong> Opens detailed information about the club.
            <br />
            <strong>Legend Manipulation:</strong> Clicking a cluster to remove
            that cluster from the chart. Adjust the threshold to see different
            clustering.
          </>
        ),
      },
    ],
    "Similarity Distance Heatmap": [
      {
        title: "Similarity Distance Heatmap Explained:",
        body: (
          <>
            <strong>Background:</strong> Follow-up to the similarity chart,
            focusing on distance.
            <br />
            <strong>Format:</strong> Symmetric matrix showcasing distances
            between clubs' preferences.
            <br />
            <strong>Value:</strong> Lower values indicate higher similarity
            between club preferences.
            <br />
            <strong>Calculation Method:</strong> Uses Kendall Tau Distance for
            measuring similarities.
            <br />
            <strong>Goal:</strong> Allows for precise comparison of similarity
            distances between any two clubs.
          </>
        ),
      },
      // ... other explanations for CDF
    ],
    "Clustering Dendrogram": [
      {
        title: "Clustering Dendrogram Explained",
        body: (
          <>
            <strong>Background:</strong> Follow-up to the similarity chart,
            focusing on clustering.
            <br />
            <strong>Dendrogram Function:</strong> Visualizes how clubs are
            clustered at different similarity thresholds.
            <br />
            <strong>Interpretation of Thresholds:</strong> Lower thresholds for
            grouping indicate higher similarity between club preferences.
            <br />
            <strong>Hover:</strong> Reveals the clubs (alternatives) in that
            group (alternatives with the exact same preference will be grouped).
            <br />
            <strong>Goal:</strong> Detailed information on the threshold
            records.
          </>
        ),
      },
      // ... other explanations for Rank Distribution
    ],
    // 'Mutual Like': [
    //     {
    //         title: 'What is Mutual Like?',
    //         body: 'Explanation for Mutual Like'
    //     },
    //     // ... other explanations for Mutual Like
    // ],
  };

  const data = {
    name: "root",
    children: [
      {
        name: "child #1",
        children: [{ name: "grandchild #1" }, { name: "grandchild #2" }],
      },
      {
        name: "child #2",
        children: [
          {
            name: "grandchild #3",
            children: [
              { name: "great grandchild #1" },
              { name: "great grandchild #2" },
            ],
          },
          { name: "grandchild #4" },
        ],
      },
    ],
  };

  const [explanations, setExplanations] = useState(
    explanationMapping["Similarity Chart"]
  );

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType);
    setExplanations(explanationMapping[newChartType]);
  };
  const [individualView2, setIndividualView2] = useState(false);

  function ordinalSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return (
        <>
          {i}
          <sup>st</sup>
        </>
      );
    }
    if (j == 2 && k != 12) {
      return (
        <>
          {i}
          <sup>nd</sup>
        </>
      );
    }
    if (j == 3 && k != 13) {
      return (
        <>
          {i}
          <sup>rd</sup>
        </>
      );
    }
    return (
      <>
        {i}
        <sup>th</sup>
      </>
    );
  }

  var rank =
    level1Info[activeTab].borda_score_stack.total
      .slice()
      .sort((a, b) => b - a)
      .indexOf(
        level1Info[activeTab].borda_score_stack.total[String(+agentNumberA - 1)]
      ) + 1;

  const [expanded, setExpanded] = useState(false);

  const handleBarClick = (altNumber) => {
    console.log("Bar Clicked in Parent Component:", `Alt ${altNumber}`);

    setAgentNumberA(altNumber);

    handleFormSubmitA();
  };
  useEffect(() => {
    if (agentNumberA) {
      setIndividualView2(true);
    }
  }, [agentNumberA]);

  const [max_d, setMaxD] = useState(0.7);
  const [currentClusterDict, setCurrentClusterDict] = useState(null);

  useEffect(() => {
    if (
      level1Info &&
      level1Info[activeTab] &&
      level1Info[activeTab].hierarchical_clustering
    ) {
      const thresholds = level1Info[activeTab].hierarchical_clustering[0];
      const clusterDicts = level1Info[activeTab].hierarchical_clustering[1];

      const thresholdIndex = thresholds.findIndex((threshold, index) => {
        return (
          max_d >= threshold &&
          (index === thresholds.length - 1 || max_d < thresholds[index + 1])
        );
      });

      if (thresholdIndex !== -1) {
        setCurrentClusterDict(clusterDicts[thresholdIndex]);
      }
    }
    console.log(currentClusterDict);
  }, [level1Info, max_d, activeTab]);

  const handleThresholdChange = (event) => {
    setMaxD(parseFloat(event.target.value));
  };

  const [selectedDendrogram, setSelectedDendrogram] = useState(null);

  return (
    descriptiveData && (
      <Container fluid style={{ width: " 95%" }}>
        <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Col md={4}>
            <Button
              style={{ padding: "1rem", width: "15rem" }}
              onClick={() => onUpdateActiveLayer("layer1card")}
              variant="outline-secondary"
            >
              <FaArrowLeft /> Back to Overview
            </Button>
          </Col>
          <Col
            md={4}
            style={{
              display: "flex",
              marginLeft: "-20px",
              marginRight: "20px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Similarity</h2>
          </Col>
          <Col md={4} style={{ textAlign: "end" }}>
            <Button
              onClick={handleUpload}
              variant="dark"
              style={{ padding: "1rem", width: "18rem" }}
            >
              Continue: Recommend Solutions <Icon icon="bytesize:arrow-right" />
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            display: "flex",
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col md={2}>
            <h4>Methods: </h4>
          </Col>
          <Col md={10}>
            <div className="section-div">
              <Row>
                <Col md={4}>
                  <div
                    className={`section ${
                      chartType === "Similarity Chart" ? "active-section" : ""
                    }`}
                    onClick={() => handleChartTypeChange("Similarity Chart")}
                  >
                    <h4 className="sectionTitle">Similarity Chart</h4>
                    <p className="sectionContent">
                      Visualize the similarity among the preferences of
                      alternatives
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div
                    className={`section ${
                      chartType === "Clustering Dendrogram"
                        ? "active-section"
                        : ""
                    }`}
                    onClick={() =>
                      handleChartTypeChange("Clustering Dendrogram")
                    }
                  >
                    <h4 className="sectionTitle">Clustering Dendrogram</h4>
                    <p className="sectionContent">
                      Understand the detailed grouping and hierarchy of similar
                      alternatives
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  <div
                    className={`section ${
                      chartType === "Similarity Distance Heatmap"
                        ? "active-section"
                        : ""
                    }`}
                    onClick={() =>
                      handleChartTypeChange("Similarity Distance Heatmap")
                    }
                  >
                    <h4 className="sectionTitle">
                      Similarity Distance Heatmap
                    </h4>
                    <p className="sectionContent">
                      A matrix summarizing the pairwise similarity between
                      alternatives
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={1}>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabSelect}
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab
                eventKey="proposer"
                title={<span className="rotate-text">Side A</span>}
                className={activeTab === "proposer" ? "active" : ""}
              ></Tab>

              <Tab
                eventKey="proposee"
                title={<span className="rotate-text">Side B</span>}
                className={activeTab === "proposee" ? "active" : ""}
              ></Tab>
            </Tabs>
          </Col>

          <Col xs={12} md={11}>
            <div className="tab-wrapper">
              <Row>
                <Col xs={12} md={8}>
                  <Card className="mb-4" style={cardStyle1}>
                    <Card.Body>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Check
                          type="switch"
                          id="individual-view-switch2"
                          label="Individual View"
                          checked={individualView2}
                          onChange={(e) => setIndividualView2(e.target.checked)}
                          style={{ flex: "1", marginTop: "6px" }}
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            height: "50px",
                            alignItems: "center",
                          }}
                        >
                          <h3>{chartType}</h3>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: "1",
                            flexDirection: "row",
                            marginTop: "-10px",
                            justifyContent: "flex-end",
                            visibility:
                              chartType === "Similarity Chart"
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <Form
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "end",
                            }}
                          >
                            <Form.Label
                              className="mb-2"
                              style={{ color: "#A9A9A9" }}
                            >
                              Threshold:
                            </Form.Label>

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Form.Range
                                value={max_d}
                                onChange={handleThresholdChange}
                                style={{
                                  width: "110px",
                                  display: "inline-block",
                                  marginBottom: "0",
                                }}
                                min="0"
                                max="1"
                                step="0.05"
                              />
                              <span
                                className="ml-2"
                                style={{
                                  marginBottom: "0",
                                  width: "40px",
                                  display: "inline-block",
                                  textAlign: "center",
                                }}
                              >
                                <strong>{max_d}</strong>
                              </span>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <Card.Text>
                        <div style={{ position: "relative", height: "520px" }}>
                          {level1Info &&
                            chartType === "Similarity Chart" &&
                            currentClusterDict && (
                              <ScatterChart
                                inputData={level1Info[activeTab].pca}
                                clusterDict={currentClusterDict}
                                onScatterClick={handleBarClick}
                              />
                            )}
                          {/* <div>
                                                            <label>Threshold: </label>
                                                            <input type="number" step="0.01" min="0" max="1" value={max_d} onChange={handleThresholdChange} />
                                                        </div> */}

                          {chartType === "Similarity Distance Heatmap" && (
                            <KtHeatMap
                              data={
                                activeTab === "proposer"
                                  ? level1Info.proposer.kendall_tau_heatmap
                                  : level1Info.proposee.kendall_tau_heatmap
                              }
                              currentTab={activeTab}
                              type="kendall tau"
                            />
                          )}
                          {chartType === "Clustering Dendrogram" && (
                            // <img src={`data:image/png;base64,${level1Info[activeTab].hierarchical_clustering[2]}`} alt="Clustering Dendrogram" style={{ maxWidth: '100%' }} />
                            <Dendrogram
                              side={activeTab === "proposer" ? "a" : "b"}
                              agents={
                                level1Info[activeTab].hierarchical_clustering[2]
                              }
                              s3_url={
                                level1Info[activeTab].hierarchical_clustering[3]
                              }
                              style={{ maxWidth: "100%" }}
                              onDendrogramClick={handleBarClick}
                            />
                          )}
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card className="mb-4" style={cardStyle1}>
                    <Card.Body>
                      <Card.Text>
                        <>
                          <div
                            className="section-div"
                            style={{ position: "relative", height: "570px" }}
                          >
                            <Accordion
                              defaultActiveKey="0"
                              flush
                              className="custom-accordion"
                            >
                              {individualView2 ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      height: "50px",
                                      marginBottom: "1rem",
                                      marginTop: "2rem",
                                    }}
                                  >
                                    <Form
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                      onSupmit={handleFormSubmitA}
                                    >
                                      <Form.Group
                                        style={{
                                          justifyContent: "center",
                                          display: "flex",
                                          gap: "1rem",
                                          flexDirection: "row",
                                          textAlign: "center",
                                        }}
                                        controlId="formAgentNumber"
                                      >
                                        <Form.Label>
                                          <Card.Title>
                                            <h3>For Alternative:</h3>
                                          </Card.Title>
                                        </Form.Label>
                                        <Form.Control
                                          style={{
                                            maxWidth: "100px",
                                            textAlign: "center",
                                            justifyContent: "center",
                                          }}
                                          type="number"
                                          value={agentNumberA || ""}
                                          placeholder="Alt A"
                                          onChange={handleNumberChangeA}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                  <Card.Text>
                                    <div
                                      style={{
                                        position: "relative",
                                        height: "408px",
                                        margin: "1rem",
                                      }}
                                    >
                                      {level1Info &&
                                        level1Info[activeTab]
                                          .borda_score_stats && (
                                          <div>
                                            <table className="styled-table">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: "70%" }}>
                                                    Statistic
                                                  </th>
                                                  <th style={{ width: "30%" }}>
                                                    Value
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <InfoTableRow
                                                  title="Borda Score"
                                                  tooltipText="The Borda Score is the sum of the positions this alternative holds in all of the other side's alternatives' preference lists. A higher score means this alternative is generally preferred."
                                                  value={
                                                    level1Info[activeTab]
                                                      .borda_score_stack.total[
                                                      String(+agentNumberA - 1)
                                                    ] || "N/A"
                                                  }
                                                  suffix={ordinalSuffix(rank)}
                                                />
                                                <InfoTableRow
                                                  title="Average Ranked by B as"
                                                  tooltipText="This is the average position that the other side's alternatives assigned to this alternative in their preference lists. A lower number means this alternative is generally ranked higher."
                                                  value={
                                                    level1Info[activeTab]
                                                      .borda_score_stats[
                                                      "Mean Rank"
                                                    ][String(agentNumberA)] ||
                                                    "N/A"
                                                  }
                                                />
                                                {expanded && (
                                                  <>
                                                    <InfoTableRow
                                                      title="Median Ranked by B as"
                                                      tooltipText="This is the middle position that the other side's alternatives assigned to this alternative when all the rankings are lined up in numerical order."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Median Rank"
                                                        ][
                                                          String(agentNumberA)
                                                        ] || "N/A"
                                                      }
                                                    />
                                                    <InfoTableRow
                                                      title="Mode Ranked by B as"
                                                      tooltipText="This is the position that this alternative most frequently holds in the other side's alternatives' preference lists."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Mode Rank"
                                                        ][String(agentNumberA)]
                                                          ? level1Info[
                                                              activeTab
                                                            ].borda_score_stats[
                                                              "Mode Rank"
                                                            ][
                                                              String(
                                                                agentNumberA
                                                              )
                                                            ]
                                                              .slice(0, 5)
                                                              .join(", ") +
                                                            (level1Info[
                                                              activeTab
                                                            ].borda_score_stats[
                                                              "Mode Rank"
                                                            ][
                                                              String(
                                                                agentNumberA
                                                              )
                                                            ].length > 5
                                                              ? ", ..."
                                                              : "")
                                                          : "N/A"
                                                      }
                                                    />
                                                    <InfoTableRow
                                                      title="Ranking Position Range"
                                                      tooltipText="This shows the highest and lowest positions this alternative holds across all of the other side's alternatives' preference lists."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Range of Rank"
                                                        ][
                                                          String(agentNumberA)
                                                        ] || "N/A"
                                                      }
                                                    />
                                                    <InfoTableRow
                                                      title="Unranked Occurrences"
                                                      tooltipText="This is the number of times this alternative was not ranked in the other side's alternatives' preference lists."
                                                      value={
                                                        level1Info[activeTab]
                                                          .borda_score_stats[
                                                          "Truncated Size"
                                                        ][
                                                          String(agentNumberA)
                                                        ] || "0"
                                                      }
                                                    />
                                                    {/* <InfoTableRow
                                                                                title={
                                                                                    <div>
                                                                                    <span>Similarity Distance Heatmap Distance between Alt {String(agentNumberA)} & Alt</span>
                                                                                    <Form style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }} onSubmit={handleFormSubmitB}>
                                                                                        <Form.Group style={{ justifyContent: 'center', display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center', marginBottom: 0 }} controlId="formAgentNumber">
                                                                                        <Form.Label><Card.Title style={{ display: 'inline' }}><h6></h6></Card.Title></Form.Label>
                                                                                        <Form.Control style={{ maxWidth: '60px', maxHeight:'30px', textAlign: 'center', justifyContent: 'center', marginBottom:'10px', marginLeft:'-10px'}} type="number" value={agentNumberB || ''} placeholder="B" onChange={handleNumberChangeB} />
                                                                                        </Form.Group>
                                                                                    </Form>
                                                                                    </div>
                                                                                }
                                                                                tooltipText="Similarity Distance Heatmap distance" 
                                                                                value={level1Info[activeTab].kendall_tau_row[agentNumberA - 1][agentNumberB - 1] || '0'}
                                                                                /> */}
                                                  </>
                                                )}
                                              </tbody>
                                            </table>
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <i
                                                className={`fa fa-chevron-${
                                                  expanded ? "up" : "down"
                                                }`}
                                                onClick={() =>
                                                  setExpanded(!expanded)
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            </div>
                                            {!expanded &&
                                              level1Info[activeTab]
                                                .borda_score_stack.distribution[
                                                agentNumberA - 1
                                              ] && (
                                                <KendallTauBar
                                                  data={
                                                    level1Info[activeTab]
                                                      .kendall_tau_row[
                                                      agentNumberA - 1
                                                    ]
                                                  }
                                                />
                                              )}
                                            {/* <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', height: '50px', marginBottom: '3rem', marginTop: '3rem' }}>
                                                                        <Form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} onSubmit={handleFormSubmitB}>
                                                                            <Form.Group style={{ justifyContent: 'center', display: 'flex', gap: '1rem', flexDirection: 'row', textAlign: 'center' }} controlId="formAgentNumber">
                                                                            <Form.Label><Card.Title><h6>Similarity Distance Heatmap Distance with:</h6></Card.Title></Form.Label>
                                                                            <Form.Control style={{ maxWidth: '100px', textAlign: 'center', justifyContent: 'center' }} type="number" value={agentNumberB || ''} placeholder="Alt B" onChange={handleNumberChangeB} />
                                                                            </Form.Group>
                                                                        </Form>
                                                                        </div> */}
                                          </div>
                                        )}
                                    </div>
                                  </Card.Text>
                                </>
                              ) : (
                                <>
                                  {explanations.map((explanation, index) => (
                                    <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                        {explanation.title}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {explanation.body}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ))}
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "0",
                                      width: "100%",
                                      textAlign: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginBottom: "5px",
                                      padding: "5px",
                                    }}
                                  >
                                    <strong>For more insights:</strong>{" "}
                                    <a
                                      href="/learn#topic1-sub1"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "underline",
                                        color: "#007BFF",
                                      }}
                                    >
                                      Explore Learn
                                    </a>
                                  </div>
                                </>
                              )}
                            </Accordion>
                          </div>
                        </>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* View previous and view next */}
        {/* <Row style={{marginTop:"2rem", marginBottom:'1rem'}}>
                    <Col style={{display:'flex', justifyContent:'start'}}>
                        <Button style={{padding:'1rem', width:'15rem'}} onClick={() => onUpdateActiveLayer('popularity')} variant="outline-secondary"> <Icon icon="bytesize:arrow-left" /> View previous: Popularity </Button>
                    </Col>
                    <Col style={{display:'flex', justifyContent:'end'}}>
                        <Button style={{padding:'1rem', width:'15rem'}} onClick={() => onUpdateActiveLayer('indifference')} variant="dark">View next: Indifference <Icon icon="bytesize:arrow-right" /></Button>
                    </Col>
                </Row> */}
      </Container>
    )
  );
};

export default DiversityAdvance;
