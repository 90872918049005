import React, { useState, useEffect } from 'react';
import ReactFlow, { Controls, Background, Position } from 'reactflow';



function getCommunityColor(communityId) {
    const colors = ['#2B6CB0', '#f2570a', '#48c78e', '#d94848', '#9457eb'];  
    return colors[communityId % colors.length];
}

function transformEdgeWeight(weight) {
  const scaleFactor = 2;  
  return Math.sqrt(weight) * scaleFactor;
  return weight
}

function getCommunityCenter(communityId, totalCommunities, mainRadius, mainCenterX, mainCenterY) {
  const angle = (communityId / totalCommunities) * (2 * Math.PI) - Math.PI / 2;
  const x = mainCenterX + mainRadius * Math.cos(angle);
  const y = mainCenterY + mainRadius * Math.sin(angle);
  return { x, y };
}

function matrixToNodesAndEdges(matrix, hideUnconnected, communities, communityView, activeNode, collapseView) {
    let nodes = [];
    const edges = [];
    let collapsedEdges = [];
    const connectedNodes = new Set();
    const n = matrix.length; 

    

   
    for (let i = 0; i < n; i++) {
      for (let j = i + 1; j < n; j++) { 
          if (matrix[i][j] > 0) {
              const normalizedWeight = transformEdgeWeight(matrix[i][j]);
              let firstActiveNode;
              if (activeNode !== null && activeNode !== undefined) {
                  const activeNodeArray = Array.from(activeNode);
                  firstActiveNode = activeNodeArray[0];
              }
              const hidden = firstActiveNode !== undefined && !(firstActiveNode === 'node' + i || firstActiveNode === 'node' + j);
              
              edges.push({
                  id: 'edge' + i + '-' + j,
                  source: 'node' + i,
                  target: 'node' + j,
                  type: 'default',
                  style: { strokeWidth: normalizedWeight }, 
                  originalStrokeWidth: matrix[i][j], 
                  hidden, 
              });
              connectedNodes.add(i);
              connectedNodes.add(j);
          }
      }
    }
    
   
    const radius = 200;
    const centerX = 400;
    const centerY = 300;
    const visibleNodeCount = hideUnconnected ? connectedNodes.size : n;
    const hiddenNodeCount = n - visibleNodeCount;
    
    const communityCenters = {};
    const communityNodeCounts = {};
    const totalCommunities = new Set(Object.values(communities)).size - hiddenNodeCount;

    for (let i = 0; i < n; i++) {
        const communityId = communities[i];
        if (!communityCenters[communityId]) {
            if (hideUnconnected && !connectedNodes.has(i)) {
              continue;
            }
            const communityCenter = getCommunityCenter(communityId, totalCommunities, radius+10, centerX, centerY);
            communityCenters[communityId] = {
                x: communityCenter.x,  
                y: communityCenter.y,  
                count: 0
            };
        }
        communityNodeCounts[communityId] = (communityNodeCounts[communityId] || 0) + 1;
    }

    let nodeIndex = 0;
    for (let i = 0; i < n; i++) {
        if (hideUnconnected && !connectedNodes.has(i)) {
            continue;  
        }
    

        let angle = (nodeIndex / visibleNodeCount) * (2 * Math.PI) - Math.PI / 2;  
        let x = centerX + radius * Math.cos(angle) - 25; 
        let y = centerY + radius * Math.sin(angle) - 25; 
    
        if (communityView) {
           
            const communityId = communities[i];
            const communityCenter = communityCenters[communityId];
            const communityNodeCount = communityNodeCounts[communityId];
            const angleOffset = (communityCenter.count / communityNodeCount) * (2 * Math.PI);
            x = communityCenter.x + (radius / 2) * Math.cos(angleOffset) - 25;
            y = communityCenter.y + (radius / 2) * Math.sin(angleOffset) - 25;
            
            
            if (!hideUnconnected || connectedNodes.has(i)) {
                communityCenter.count++;
            }
        }

        const isConnected = edges.some(edge => edge.source === `node${i}` || edge.target === `node${i}`);
        const isActive = `node${i}` === activeNode;
        const isGreyedOut = activeNode && !activeNode.has(`node${i}`);

        nodes.push({
            id: 'node' + i,
            type: 'default',
            data: { label: (i + 1).toString() },
            className: 'circle',
            style: {
                background: isGreyedOut ? '#d3d3d3' : getCommunityColor(communities[i]), 
             
                color: 'white',
                width: 50,
                height: 50,
                borderRadius: '50%',
            },
            position: { x, y },
            sourcePosition: Position.Left,
            targetPosition: Position.Right,
        });
        nodeIndex++;
    }

    if (collapseView) {
       
        nodes = [];
        collapsedEdges = [];
        
        const communityEdges = {};
        const communityInternalEdges = {};
        
        for (const edge of edges) {
            const sourceCommunity = communities[parseInt(edge.source.slice(4))];
            const targetCommunity = communities[parseInt(edge.target.slice(4))];
            
            if (sourceCommunity === targetCommunity) {
           
                communityInternalEdges[sourceCommunity] = (communityInternalEdges[sourceCommunity] || 0) + edge.originalStrokeWidth;
            } else {
             
                const edgeId = `C${sourceCommunity}-C${targetCommunity}`;
                communityEdges[edgeId] = (communityEdges[edgeId] || 0) + edge.originalStrokeWidth;
            }
        }
        
        for (const communityId of Object.keys(communityCenters)) {
            nodes.push({
                id: 'C' + communityId,
                type: 'default',
                data: { label: 'C ' + (parseInt(communityId, 10) + 1).toString() },
                className: 'circle',
                style: {
                    background: getCommunityColor(communityId),
                    color: 'white',
                    width: 70,
                    height: 70,
                    borderRadius: '50%',
                },
                position: communityCenters[communityId],
                sourcePosition: Position.Left,
                targetPosition: Position.Middle,
            });
            
            if (communityInternalEdges[communityId]) {
                edges.push({
                    id: 'edgeInternal' + communityId,
                    source: 'C' + communityId,
                    target: 'C' + communityId,
                    type: 'default',
                    style: { strokeWidth: transformEdgeWeight(communityInternalEdges[communityId]) }, 
                    label: communityInternalEdges[communityId].toString(),
                  });
                }
              }
            
              
            for (const [edgeId, strokeWidth] of Object.entries(communityEdges)) {
              const [source, target] = edgeId.split('-');
              edges.push({
                id: edgeId,
                source,
                target,
                type: 'default',
                style: { strokeWidth: transformEdgeWeight(strokeWidth) }, 
                label: strokeWidth.toString(),
              
          });
        }
    }

    return { nodes, edges }; 

}

function IndifferenceNetwork({ matrix, communities, onBarClick }) {
  const [hideUnconnected, setHideUnconnected] = useState(false);
  const [communityView, setCommunityView] = useState(false);
  const [activeNode, setActiveNode] = useState(null);  
  const [nodesEdges, setNodesEdges] = useState({ nodes: [], edges: [] });
  const [collapseView, setCollapseView] = useState(false);

  const handleNodeClick = (event, node) => {
    const connectedNodes = new Set([node.id]);
    nodesEdges.edges.forEach(edge => {
        if (edge.source === node.id) {
            connectedNodes.add(edge.target);
        } else if (edge.target === node.id) {
            connectedNodes.add(edge.source);
        }
    });
    setActiveNode(connectedNodes);
    const firstActiveNodeArray = Array.from(connectedNodes);
    const firstActiveNodeId = firstActiveNodeArray[0];
    const firstActiveNodeInt = parseInt(firstActiveNodeId.slice(4), 10 + 1);
    
    onBarClick(firstActiveNodeInt);  
    
  };
  const handlePaneClick = () => {
    setActiveNode(null); 
  };




  useEffect(() => {
    if (collapseView) {
      setCommunityView(true);
    }
    const { nodes, edges } = matrixToNodesAndEdges(matrix, hideUnconnected, communities, communityView, activeNode, collapseView);  // Pass activeNode to function
    setNodesEdges({ nodes, edges });
  }, [matrix, hideUnconnected, communities, communityView, activeNode, collapseView]); 


  return (
      <div style={{ height: '95%' }}>
          <input
              type="checkbox"
              checked={hideUnconnected}
              onChange={() => setHideUnconnected(!hideUnconnected)}
              style={{ margin: '10px' }}
          />
          Hide unconnected nodes
          <input
              type="checkbox"
              checked={communityView}
              onChange={() => setCommunityView(!communityView)}
              style={{ margin: '10px' }}
          />
          Community view
          <input
                type="checkbox"
                checked={collapseView}
                onChange={() => setCollapseView(!collapseView)}
                style={{ margin: '10px' }}
            />
          Collapse view
          <ReactFlow
              nodes={nodesEdges.nodes}
              edges={nodesEdges.edges}
              fitView={true}
              onNodeClick={handleNodeClick}  
              onPaneClick={handlePaneClick} 
            >
            <Background gap={24} />
            <Controls />
          </ReactFlow>
      </div>
  );
}

export default IndifferenceNetwork;