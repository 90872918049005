import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import hadi from "./Hosseini-Hadi.jpg";
import yubo from "./YuboJ.jpg";
import ronak from "./Ronak.jpeg";

function FileFormat() {
  const leftColumnStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: "0px",
    borderRight: "2px solid #eee",
  };

  const rightColumnStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "60px",
    alignItems: "center",
    // justifyContent:'center',
    paddingTop: "1.5rem",
    gap: "5rem",
  };

  const principalStyle = {
    textAlign: "center",
    marginBottom: "30px",
  };

  const imageStylePrincipal = {
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    marginBottom: "20px",
  };

  const memberStyle = {
    display: "flex",
    marginBottom: "30px",
  };

  const imageStyleDeveloper = {
    width: "160px",
    height: "160px",
    borderRadius: "50%",
    marginRight: "15px",
  };

  const descriptionStyle = {
    textAlign: "left",
    fontSize: "1rem",
  };

  return (
    <Container style={{ backgroundColor: "#fff", color: "#333", width: "90%" }}>
      <Row className="my-5 justify-content-center">
        <Col md={11} className="d-flex flex-column justify-content-center">
          <h2
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
          >
            File Format
          </h2>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            When uploading preferences to MatchXplain, all input files must
            share a common file format. Input files contain two parts, first a
            list of metadata starting with a "#"; second the preferences
            themselves. All uploaded input files must be text files with the
            ".txt" suffix, and there shouldn't be any spaces in the file name.
          </p>
          <br />

          <h3
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
          >
            Metadata
          </h3>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            Let us describe each of the metadata that are common to all files,
            below is an example of a valid input file:
          </p>
          <br />

          <Row>
            <Col md={4} className="d-flex flex-column justify-content-center">
              <pre
                style={{
                  lineHeight: "1.6",
                  backgroundColor: "rgb(230,230,230)",
                  paddingLeft: "5px",
                  borderRadius: "10px",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <code>
                  # PREFERENCE TYPE: toc
                  <br /># MATCH TYPE: two-sided
                  <br /># MATCH TYPE: one-one
                  <br />
                  12,12
                  <br />
                  1: 1,5,6,3,2,4,7,&#123;8,9,10,11&#125;,12
                  <br />
                  2: 7,2,10,1,3,4,5,6,8,9,11,12
                  <br />
                  3: 3,2,1,4,5,6,7,8,9,10,11,12
                  <br />
                  4: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  5: 3,2,1,4,5,6,7,8,9,10,11,12
                  <br />
                  6: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  7: 3,2,1,4,5,6,7,8,9,10,11,12
                  <br />
                  8: 3,2,1,4,5,6,7,8,9,10,11,12
                  <br />
                  9: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  10: 2,3,4,7,8,1,12,5,6,9,10,11
                  <br />
                  11: 9,5,6,2,3,4,7,8,1,10,11,12
                  <br />
                  12: 4,2,1,3,5,6,7,8,9,10,11,12
                  <br />
                  1: 1,5,6,3,2,4,7,8,9,10,11,12
                  <br />
                  2: 7,2,10,1,3,4,5,6,&#123;8,9,11&#125;,12
                  <br />
                  3: 3,2,1,4,5,6,7,8,9,10,11,12
                  <br />
                  4: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  5: 3,2,1,4,5,6,7,&#123;8,9,10&#125;,11,12
                  <br />
                  6: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  7: 3,2,1,4,5,6,7,8,9,10,11,12
                  <br />
                  8: 2,3,4,7,8,1,12,5,6,9,10,11
                  <br />
                  9: 9,5,6,2,3,4,7,8,1,10,11,12
                  <br />
                  10: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  11: 1,8,4,2,3,5,6,7,9,10,11,12
                  <br />
                  12: 4,2,1,3,5,6,7,8,9,10,11,12
                </code>
              </pre>
            </Col>
            <Col>
              <p
                style={{
                  fontSize: "1.25rem",
                  color: "rgb(120,120,120)",
                  paddingLeft: "5px",
                }}
              >
                <code
                  style={{
                    fontSize: "1.25rem",
                    color: "rgb(120,120,120)",
                  }}
                >
                  PREFERENCE TYPE:
                </code>{" "}
                This is the type of preferences contained in the file as
                described <a href="#preference-types">below</a>.
                <br />
                <br />
                <code
                  style={{
                    fontSize: "1.25rem",
                    color: "rgb(120,120,120)",
                  }}
                >
                  MATCH TYPE:
                </code>{" "}
                The first "MATCH TYPE" metadata describes whether the problem
                requires one-sided or two-sided matching. Two-sided matching
                refers to when both sets of agents have preferences over the
                other side. Often, this is the case when matching two groups of
                people with each other, like in mentor-mentee matching or job
                matching. Support for one-sided matchings is coming soon!
                <br />
                <br />
                <code
                  style={{
                    fontSize: "1.25rem",
                    color: "rgb(120,120,120)",
                  }}
                >
                  MATCH TYPE:
                </code>{" "}
                The second "MATCH TYPE" metadata describes whether the problem
                requires one-one or two-many matching. One-one matchings refer
                to when each agent is matched with at most one partner. Often,
                this is the case when trying to pair up two groups of people
                together. Support for one-many matchings is coming soon!
              </p>
            </Col>
          </Row>
          <br />
          <h3
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
            id="preference-types"
          >
            Preference Types
          </h3>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            Each input file must have a preference type (denoted by PREFERENCE
            TYPE in the file's metadata) which represents the type of
            preferences that are expressed in the input file. As more preference
            types are accepted, we will add them to this section. We will review
            all currently supported types below:
          </p>
          <h3
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
              color: "rgb(120,120,120)",
            }}
            id="preference-types"
          >
            soc: Strict Orders, Complete Lists
          </h3>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            Input files of "PREFERENCE TYPE: soc" contain preferences
            represented by a strict and complete linear order (transitive, and
            asymmetric relation) over the agents on the other side. This means
            that every agent ranks every agent on the other side on their
            preferences, and no agent includes ties in their preferences.
          </p>
          <h3
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
              color: "rgb(120,120,120)",
            }}
            id="preference-types"
          >
            toc: Orders with Ties, Complete Lists
          </h3>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            Input files of "PREFERENCE TYPE: toc" contain preferences
            represented by a transitive and complete relation over the agents on
            the other side. This means that every agent ranks every agent on the
            other side on their preferences, and agent may include ties in their
            preferences (denoted by braces).
          </p>
          <br />
          <h3
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "0.5em",
            }}
          >
            Preferences
          </h3>
          <p
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              color: "rgb(120,120,120)",
              paddingLeft: "5px",
            }}
          >
            Let us describe each component of the preferences for input files.
            In the example above, information about the size of the instance is
            immedietly below the metadata. Two numbers, separated by a comma,
            must be specified to indicate the number of agents on each side of
            the matching (ex. 12,12 indicates there are 12 agents on each side
            of the problem). <br />
            <br />
            After the size of the istance is specified, input files contain the
            preferences for every agent, with each line corresponding to the
            preferences that one agent has over the other set of agents (the
            format of the preferences can be seen in the example above). Note
            that preferences are 1-indexed, meaning that the first agent should
            be denoted as agent 1, not agent 0. Additionally, preferences in
            braces indicate ties—in the example above, we can see that on line
            5, agent 1's preferences contain a four way tie between agents 8, 9,
            10, and 11.
            <br />
            <br />
            If a problem is two-sided, then make sure to first include the
            preferences of the agents of the first side, then include the
            preferences of the agents of the second side (see example above).
            Also, ensure that the metadata of an input file is consistent with
            the preferences of the file. For example, an input file with
            PREFERENCE TYPE "soc" shouldn't also contain preferences with ties.
          </p>
          <br />
        </Col>
      </Row>
    </Container>
  );
}

export default FileFormat;
