import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const mul = 1.41;
const add = 3.5;

function generateColors(numColors) {
  const baseColors = [
    'hsla(351, 100%, 86%, 1)',   // lightpink
    'hsla(195, 53%, 79%, 1)',    // lightblue
    'hsla(120, 73%, 75%, 1)',    // lightgreen
    'hsla(54, 77%, 75%, 1)',     // khaki
    'hsla(15, 72%, 70%, 1)',     // darksalmon
    'hsla(219, 79%, 66%, 1)',    // cornflower
    'hsla(350, 100%, 68%, 1)',   // pink
    'hsla(56, 38%, 58%, 1)',     // darkkhaki
    'hsla(0, 0%, 75%, 1)',       // silver
    'hsla(280, 61%, 40%, 1)',    // darkorchid
    'hsla(33, 100%, 50%, 1)',    // darkorange
    'hsla(300, 100%, 50%, 1)',   // magenta
    'hsla(180, 100%, 97%, 1)',   // lightcyan
    'hsla(51, 100%, 50%, 1)',    // gold
    'hsla(0, 59%, 41%, 1)',      // brown
    'hsla(120, 100%, 50%, 1)',   // lime
    'hsla(180, 100%, 50%, 1)',   // aqua
    'hsla(16, 100%, 50%, 1)',    // orangered
    'hsla(60, 100%, 94%, 1)',    // lightyellow
    'hsla(0, 100%, 50%, 1)',     // red
    'hsla(85, 39%, 20%, 1)',     // darkolivegreen
    'hsla(120, 100%, 25%, 1)',   // green
    'hsla(60, 100%, 25%, 1)',    // olive
    'hsla(275, 100%, 25%, 1)',   // indigo
    'hsla(300, 100%, 25%, 1)',   // purple
    'hsla(0, 100%, 27%, 1)',     // darkred
    'hsla(300, 100%, 27%, 1)',   // darkmagenta
    'hsla(180, 100%, 27%, 1)',   // darkcyan
    'hsla(240, 100%, 27%, 1)',   // darkblue
    'hsla(0, 100%, 25%, 1)',     // maroon
    'hsla(240, 100%, 25%, 1)',   // navy
    'hsla(0, 0%, 33%, 1)',       // darkgrey
    'hsla(120, 100%, 20%, 1)',   // darkgreen
    'hsla(0, 0%, 0%, 1)',        // black
];




  
  const colors = baseColors.slice(0, numColors);
  
  while (colors.length < numColors) {
    const hue = Math.random() * 360;
    const saturation = Math.random() * (100 - 60) + 30;
    const lightness = Math.random() * (60 - 40) + 40;
    const color = `hsla(${Math.round(hue)}, ${Math.round(saturation)}%, ${Math.round(lightness)}%, 0.6)`;
    let isDistinct = true;
    for (let i = 0; i < colors.length; i++) {
      const existingColor = colors[i];
      if (Math.abs(hue - existingColor.hue) < 20 && Math.abs(saturation - existingColor.saturation) < 20 && Math.abs(lightness - existingColor.lightness) < 20) {
        isDistinct = false;
        break;
      }
    }
    if (isDistinct) {
      colors.push(color);
    }
  }
  
  return colors;
}







const ScatterChart = ({ inputData, clusterDict, onScatterClick }) => {
  const numClusters = Object.keys(clusterDict).length;
  const colorList = generateColors(numClusters);

  const processData = (inputData, clusterDict) => {
    const datasets = [];

    console.log('inputData:', inputData);
    console.log('clusterDict:', clusterDict);

    for (const clusterLabel in clusterDict) {
      const clusterMembers = clusterDict[clusterLabel];
      console.log('clusterMembers:', clusterMembers);

      const dataPoints = clusterMembers.map((index) => {
        console.log('index:', index);
        if (index >= inputData.length) {
            console.error('Invalid index:', index);
            return null;
        }
        const item = inputData[index];
        console.log('item:', item);
        return {
          x: item.PC1,
          y: item.PC2,
          label: item.agents,
          quantity: item.quantity,
        };
      }).filter(item => item !== null);
    

      datasets.push({
        label: `Cluster ${clusterLabel}`,
        data: dataPoints,
        backgroundColor: colorList[parseInt(clusterLabel)-1],
        pointRadius: dataPoints.map((item) => add + item.quantity * mul),
        pointHoverRadius: dataPoints.map((item) => item.quantity * mul + add + 1),
        itemquantity: dataPoints.map((item) => item.quantity),
      });
    }

    return { datasets };
  };


  const chartData = processData(inputData, clusterDict);

  const allDataPoints = inputData.map(data => [data.PC1, data.PC2]).flat();
  const minAxisValue = Math.min(...allDataPoints) - 0.1; // Adding some padding
  const maxAxisValue = Math.max(...allDataPoints) + 0.1; // Adding some padding

  const chartOptions = {
    onClick: function (evt, activeElements) {
      if (!activeElements || activeElements.length === 0) {
        console.log("No active points found.");
        return;
      }
    
      const chart = this;
      const firstPoint = activeElements[0];
    
      // Get the dataset and data index of the clicked point
      const clickedDataset = chart.data.datasets[firstPoint.datasetIndex];
      const clickedData = clickedDataset.data[firstPoint.index];
    
      // Parse the label string as JSON to get an actual array, then get the first element
      const agentArray = JSON.parse(clickedData.label);
      const agent = agentArray[0];
      console.log("Agent:", agent);
    
      // Call the provided onScatterClick function with the clicked agent
      onScatterClick && onScatterClick(agent);
    },
    
    
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,  // hide x-axis line and labels
        min: minAxisValue,
        max: maxAxisValue,
        title: {
          display: false,
          text: 'PC1',
        },
        ticks: {
          display: false  // hide x-axis ticks
        },
        grid: {
          display: true,  // show x-axis grid lines
        },
      },
      y: {
        display: true,  // hide y-axis line and labels
        min: minAxisValue,
        max: maxAxisValue,
        title: {
          display: false,
          text: 'PC2',
        },
        ticks: {
          display: false  // hide y-axis ticks
        },
        grid: {
          display: true,  // show y-axis grid lines
        },
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: false,
        text: 'Kendall Tau Distance PCA Scatter Plot',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, dataIndex } = context;
            const data = dataset.data[dataIndex];
            return `${dataset.label}: Alternative: ${data.label}, Quantity: ${dataset.itemquantity[dataIndex]}`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };

  return (
    <div style={{ height: '500px', width: '500px', marginLeft:'7rem' }}>
      <Scatter options={chartOptions} data={chartData} />
    </div>
  );
};


export default ScatterChart;
