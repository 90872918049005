import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BordaStackedChart = ({ data, onBarClick, topSlice }) => {
  const [scoreToExclude, setScoreToExclude] = useState(Array(data.total.length).fill(0));

  const getSortedAgents = (scoreToExclude) => {
    // Copying scoreToExclude
    let scoreToExcludeCopy = [...scoreToExclude];
    
    // Copying data
    let dataCopy = JSON.parse(JSON.stringify(data));
    
    return dataCopy.total
      .map((score, index) => ({ x: `Alt ${index + 1}`, y: score - scoreToExcludeCopy[index], index }))
      .sort((a, b) => b.y - a.y)
      .slice(0, topSlice);
  }

  const newLegendClickHandler = function (e, legendItem, legend) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;

    const excludedKey = index !== null ? ['first', 'second', 'third', 'fourth'][index] : null;

    // Copying scoreToExclude
    let scoreToExcludeCopy = [...scoreToExclude];

    // Copying data
    let dataCopy = JSON.parse(JSON.stringify(data));

    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;

      const newScoreToExclude = scoreToExcludeCopy.map((value, i) => value + dataCopy[excludedKey][i]);
      setScoreToExclude(newScoreToExclude);
      setSortedAgents(getSortedAgents(newScoreToExclude));
    } else {
      ci.show(index);
      legendItem.hidden = false;

      const newScoreToExclude = scoreToExcludeCopy.map((value, i) => value - dataCopy[excludedKey][i]);
      setScoreToExclude(newScoreToExclude);
      setSortedAgents(getSortedAgents(newScoreToExclude));
    }
  };

  const [sortedAgents, setSortedAgents] = useState(getSortedAgents(scoreToExclude));

  useEffect(() => {
    setScoreToExclude(Array(data.total.length).fill(0));
    setSortedAgents(getSortedAgents(Array(data.total.length).fill(0)));
  }, [data]);
  useEffect(() => {
    setSortedAgents(getSortedAgents(scoreToExclude));
}, [topSlice]);

  const createSeriesData = (key) => {
    return sortedAgents.map((agent) => data[key][agent.index]);
  };

  const chartData = {
    labels: sortedAgents.map((agent) => agent.x),
    datasets: data.total.length < 4 ? [
      {
        label: 'Votes from Range 0-50%',
        data: createSeriesData('first'),
        backgroundColor: 'rgba(0, 34, 108, 0.8)',
      },
      {
        label: 'Votes from Range 50-100%',
        data: createSeriesData('second'),
        backgroundColor: 'rgba(4, 80, 194, 0.8)',
      },
    ] : [
      {
        label: 'Votes from Q1',
        data: createSeriesData('first'),
        backgroundColor: 'rgba(0, 34, 108, 0.8)',
      },
      {
        label: 'Votes from Q2',
        data: createSeriesData('second'),
        backgroundColor: 'rgba(4, 80, 194, 0.8)',
      },
      {
        label: 'Votes from Q3',
        data: createSeriesData('third'),
        backgroundColor: 'rgba(0, 115, 220, 0.7)',
      },
      {
        label: 'Votes from Q4',
        data: createSeriesData('fourth'),
        backgroundColor: 'rgba(0, 137, 220, 0.5)',
      },
    ],    
  };

  const chartOptions = {

    events: ['click', 'mousemove'],

    onClick: function(evt, activeElements) {
      console.log("Active Elements:", activeElements);
      
      if (!activeElements || activeElements.length === 0) {
          console.log("No active points found.");
          return;
      }
      
      const chart = this;
      const firstPoint = activeElements[0];
  
      const clickedBarLabel = chart.data.labels[firstPoint.index];
      console.log("Label:", clickedBarLabel);
  
      const altNumber = parseInt(clickedBarLabel.split(" ")[1], 10);
      onBarClick && onBarClick(altNumber);
  },
  
    indexAxis: 'y', 
    plugins: {
      title: {
        display: false,
        text: 'Popularity Scores of Alternatives',
      },
      legend: {
        onClick: newLegendClickHandler,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const index = context.dataIndex;
            const agentIndex = getSortedAgents(scoreToExclude)[index].index;
            let count = 0;
            let bordaScore = 0;

            switch (dataset.label) {
              case 'Votes from Q1':
                count = data.first_count[agentIndex];
                bordaScore = data.first[agentIndex];
                break;
              case 'Votes from Q2':
                count = data.second_count[agentIndex];
                bordaScore = data.second[agentIndex];
                break;
              case 'Votes from Q3':
                count = data.third_count[agentIndex]; 
                bordaScore = data.third[agentIndex];
                break;
              case 'Votes from Q4':
                count = data.fourth_count[agentIndex];
                bordaScore = data.fourth[agentIndex];
                break;
              case 'Votes from Range 0-50%':
                count = data.first_count[agentIndex];
                bordaScore = data.first[agentIndex];
                break;
              case 'Votes from Range 50-100%':
                count = data.second_count[agentIndex];
                bordaScore = data.second[agentIndex];
                break;
              default:
                break;
            }

            return `Popularity Scores: ${bordaScore} \n \n Votes Count: ${count}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Popularity Scores',
        },
      },
      y: {
        stacked: true,
        min: 0,
      },
      
    },
  };






  return (
    <div style={{ height: '500px', width: '100%' }}>
               <Bar 
            data={chartData} 
            options={chartOptions} 
        />

    </div>
  );
  
};

export default BordaStackedChart;
