import React from 'react';
import Plot from 'react-plotly.js';

const IndifferenceViolin = ({ data }) => {
    const positions = [];
    
    data.forEach((value, idx) => {
        for (let i = 0; i < value * 100; i++) { 
            positions.push(idx + 1);  
        }
    });

    const plotData = [{
        type: 'violin',
        x: positions,
        orientation: 'h', 
        points: 'none',
        box: {
            visible: true
        },
        boxpoints: false,
        line: {
            color: 'black'
        },
        fillcolor: '#8dd3c7',
        opacity: 0.6,
        meanline: {
            visible: true
        },
        name: ''
    }];

    const layout = {
        height: 300,
        width: 340,
        title: 'Tie Position Violin Plot',
        xaxis: {
            title: 'Tie Positions',
            zeroline: false,
            tickvals: Array.from({ length: data.length + 2 }, (_, i) => i),
            range: [0, data.length + 1],
            showgrid: true,
            gridcolor: '#e5e5e5' 
        },
        yaxis: {
            title: {
                text: 'Density',
                standoff: 12
            },
            titlefont: {
                size: 14,
                color: 'black'
            },
            tickfont: {
                size: 12,
                color: 'black'
            },
            showgrid: false 
        },
        hovermode: false,
        showlegend: false, 
        margin: {
            l: 30, 
            r: 20,
            b: 50,
            t: 50,
            pad: 4
        },
        plot_bgcolor: '#ffffff', 
        paper_bgcolor: '#ffffff',
        modebar: {
            orientation: 'h',
            bgcolor: 'rgba(0,0,0,0)'
        },
        displayModeBar: false 
    };

    return (
        <div style={{ height: '200px', width: '70%' }}>
            <Plot
                data={plotData}
                layout={layout}
                config={{ displayModeBar: false, responsive: true, staticPlot: true }} 
            />
        </div>
    );
};

export default IndifferenceViolin;
