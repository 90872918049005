// import { configureStore } from "@reduxjs/toolkit";
import preferenceReducer from "../features/preferenceSlice";
import solutionReducer from "../features/solutionSlice";
import dataReducer from "../features/dataSlice";
// import solutionReducer from "../features/solutionSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

// export default configureStore({
//   reducer: {
//     preference: preferenceReducer,
//     solution: solutionReducer,
//   },
// });

const persistConfig = {
  key: "root",
  storage,
  version: 0,
  whitelist: ["preference", "solution"],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const reducers = combineReducers({
  preference: preferenceReducer,
  solution: solutionReducer,
  data: dataReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: true,
});

const persistor = persistStore(store);
export { store, persistor };
