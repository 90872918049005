import { React, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import ViewDetailButton from "../ViewDetailButton";
import MiniBordaStackedChart from "./MiniBordaStackedChartMod";
import MiniScatterChart from "./MiniScatterChartMod";
import MiniTieRatioChart from "./MiniTieRatioChartMod";
import { Icon } from "@iconify/react";
import "./Layer1Card.css";

const Layer1CardMod = ({
  descriptiveData,
  onUpdateActiveLayer,
  onHide,
  handleUpload,
  chartType,
  setChartType,
}) => {
  const cardStyle1 = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.08)", // Reduced and softened shadow
    transition: "0.3s",
    borderRadius: "6px",
    border: "none",
  };

  const inputInfo = descriptiveData ? descriptiveData.input_info : null;
  const problemTypes =
    inputInfo && inputInfo.problem_type
      ? inputInfo.problem_type.split(",")
      : [];
  const preferenceTypes =
    inputInfo && inputInfo.preference_type
      ? inputInfo.preference_type.split(",")
      : [];
  const level1Info = descriptiveData ? descriptiveData.level1_info : null;
  const [activeTab, setActiveTab] = useState("proposer");
  const [activeSide, setActiveSide] = useState([]);
  const [inactiveSide, setInactiveSide] = useState([]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const [max_d, setMaxD] = useState(0.7);
  const [currentClusterDict, setCurrentClusterDict] = useState(null);

  useEffect(() => {
    if (
      level1Info &&
      level1Info[activeTab] &&
      level1Info[activeTab].hierarchical_clustering
    ) {
      const thresholds = level1Info[activeTab].hierarchical_clustering[0];
      const clusterDicts = level1Info[activeTab].hierarchical_clustering[1];
      const thresholdIndex = thresholds.findIndex((threshold, index) => {
        return (
          max_d >= threshold &&
          (index === thresholds.length - 1 || max_d < thresholds[index + 1])
        );
      });
      if (thresholdIndex !== -1) {
        setCurrentClusterDict(clusterDicts[thresholdIndex]);
      }
    }
    console.log(currentClusterDict);
    if (activeTab === "proposer") {
      setActiveSide("A");
      setInactiveSide("B");
    } else if (activeTab === "proposee") {
      setActiveSide("B");
      setInactiveSide("A");
    }
  }, [level1Info, max_d, activeTab]);

  return (
    descriptiveData && (
      <>
        <Container fluid style={{ width: " 95%" }}>
          <Row style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            <Col md={4}>
              <Button
                onClick={onHide}
                variant="secondary"
                style={{ padding: "1rem", width: "8rem" }}
              >
                Close
              </Button>
            </Col>
            <Col
              md={4}
              style={{
                display: "flex",
                marginLeft: "-20px",
                marginRight: "20px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>Explainable Data</h2>
            </Col>
            <Col md={4} style={{ textAlign: "end" }}>
              <Button
                onClick={handleUpload}
                variant="dark"
                style={{ padding: "1rem", width: "18rem" }}
              >
                Continue: Recommend Solutions{" "}
                <Icon icon="bytesize:arrow-right" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={1}>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab
                  eventKey="proposer"
                  title={<span className="rotate-text">Side A</span>}
                  className={activeTab === "proposer" ? "active" : ""}
                ></Tab>

                <Tab
                  eventKey="proposee"
                  title={<span className="rotate-text">Side B</span>}
                  className={activeTab === "proposee" ? "active" : ""}
                ></Tab>
              </Tabs>
            </Col>

            <Col xs={12} md={11}>
              <div className="tab-wrapper">
                <Row>
                  <p style={{ textAlign: "center" }}>
                    This is a <strong>{problemTypes[0]}</strong> market of size{" "}
                    <strong>n={level1Info.proposee.n}</strong> with{" "}
                    <strong>{problemTypes[1]}</strong> matching.<br></br>To
                    change which side you are viewing statistics for, pick the
                    appropriate tab on the left.
                  </p>
                  {/* <Col>{problemTypes[0]}</Col>
                  <Col>{problemTypes[1]}</Col>
                  <Col>{level1Info.proposee.n}</Col> */}
                  {/* <Col md={4} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>{problemTypes[0]}</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        Market Type
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col>
                  <Col md={4} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>{problemTypes[1]}</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        Matching Type
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col>
                  <Col md={4} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>{level1Info.proposee.n}</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        Number of Alternatives
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col> */}
                  {/* <Col md={3} style={{ textAlign: "center" }}>
                    <Card className="mb-4" style={cardStyle1}>
                      <br></br>
                      <h2>12
                        %</h2>
                      <Card.Subtitle className="mb-2 text-muted">
                        % of Incompleteness
                      </Card.Subtitle>
                      <br></br>
                    </Card>
                  </Col> */}
                </Row>
                <Row style={{ paddingBottom: "20px" }}>
                  <Col md={6}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("popularity");
                        setChartType("Popularity Score");
                      }}
                    >
                      <div style={{ width: "50%", height: "100%" }}>
                        {level1Info && (
                          // <KtHeatMap
                          //   data={
                          //     activeTab === "proposer"
                          //       ? level1Info.proposer.kendall_tau_heatmap
                          //       : level1Info.proposee.kendall_tau_heatmap
                          //   }
                          //   currentTab={activeTab}
                          //   type="kendall tau"
                          // />
                          <MiniBordaStackedChart
                            data={
                              activeTab === "proposer"
                                ? level1Info.proposer.borda_score_stack
                                : level1Info.proposee.borda_score_stack
                            }
                            currentTab={activeTab}
                          />
                        )}
                      </div>
                      <div>
                        <div style={{ display: "block", textAlign: "center" }}>
                          <h5 style={{ marginBottom: "32px" }}>
                            Popularity Score
                          </h5>
                          <p style={{ marginBottom: "41px" }}>
                            Ranks alternatives from the highest popularity score
                            to the lowest
                          </p>
                          <Icon
                            icon="lucide:users-round"
                            height={30}
                            color="#4AAFD5"
                          />
                        </div>
                      </div>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("popularity");
                        setChartType("Mutual Like Heatmap");
                      }}
                    >
                      <div style={{ display: "block", textAlign: "center" }}>
                        <h5 style={{ marginBottom: "32px" }}>
                          Mutual Like Heatmap<br></br>
                        </h5>
                        <p>
                          Lets you see if the ones alternatives like also like
                          them back
                        </p>
                        <Icon
                          icon="lucide:heart-handshake"
                          height={30}
                          color="#4AAFD5"
                        />
                      </div>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("popularity");
                        setChartType("Rank Distribution Heatmap");
                      }}
                    >
                      <div style={{ display: "block", textAlign: "center" }}>
                        <h5>Rank Distribution Heatmap</h5>
                        <p>
                          A matrix table that summarizes the ranking position
                          for each alternative
                        </p>
                        <Icon
                          icon="lucide:chart-column"
                          height={30}
                          color="#4AAFD5"
                        />
                      </div>
                    </button>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "20px" }}>
                  <Col md={6}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("similarity");
                        setChartType("Similarity Chart");
                      }}
                    >
                      <div style={{ width: "50%", height: "100%" }}>
                        {level1Info && currentClusterDict && (
                          <MiniScatterChart
                            inputData={level1Info[activeTab].pca}
                            clusterDict={currentClusterDict}
                          />
                        )}
                      </div>
                      <div>
                        <div style={{ display: "block", textAlign: "center" }}>
                          <h5 style={{ marginBottom: "32px" }}>
                            Similarity Chart
                          </h5>
                          <p style={{ marginBottom: "41px" }}>
                            Visualize the similarity among the preferences of
                            alternatives
                          </p>
                          <Icon
                            icon="lucide:equal"
                            height={30}
                            color="#91B187"
                          />
                        </div>
                      </div>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("similarity");
                        setChartType("Clustering Dendrogram");
                      }}
                    >
                      <div style={{ display: "block", textAlign: "center" }}>
                        <h5 style={{ marginBottom: "32px" }}>
                          Clustering Dendrogram
                        </h5>
                        <p>
                          Understand the detailed grouping and hierarchy of
                          similar alternatives
                        </p>
                        <Icon
                          icon="lucide:network"
                          height={30}
                          color="#91B187"
                        />
                      </div>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("similarity");
                        setChartType("Similarity Distance Heatmap");
                      }}
                    >
                      <div style={{ display: "block", textAlign: "center" }}>
                        <h5>Similarity Distance Heatmap</h5>
                        <p>
                          A matrix summarizing the pairwise similarity between
                          alternatives
                        </p>
                        <Icon icon="lucide:ruler" height={30} color="#91B187" />
                      </div>
                    </button>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "20px" }}>
                  <Col md={6}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("indifference");
                        setChartType("Ties Ratio");
                      }}
                    >
                      <div style={{ width: "55%", height: "100%" }}>
                        {level1Info && (
                          <MiniTieRatioChart
                            inputData={
                              activeTab === "proposer"
                                ? level1Info.proposer.tie_ratio_group
                                : level1Info.proposee.tie_ratio_group
                            }
                            tieRatio={
                              activeTab === "proposer"
                                ? level1Info.proposer.tie_ratio
                                : level1Info.proposee.tie_ratio
                            }
                            tabChange={activeTab === "proposer" ? "A" : "B"}
                          />
                        )}
                      </div>
                      <div>
                        <div style={{ display: "block", textAlign: "center" }}>
                          <h5 style={{ marginBottom: "32px" }}>Ties Ratio</h5>
                          <p>
                            An overview of how alternatives are indifferent
                            about their preferences
                          </p>
                          <Icon
                            icon="lucide:chart-pie"
                            height={30}
                            color="#E7A339"
                          />
                        </div>
                      </div>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("indifference");
                        setChartType("Ties Network");
                      }}
                    >
                      <div style={{ display: "block", textAlign: "center" }}>
                        <h5 style={{ marginBottom: "32px" }}>Ties Network</h5>
                        <p style={{ marginBottom: "41px" }}>
                          Visualizes the tied alternatives as a visual graph
                          network
                        </p>
                        <Icon
                          icon="lucide:waypoints"
                          height={30}
                          color="#E7A339"
                        />
                      </div>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="bento-grid-box"
                      onClick={() => {
                        onUpdateActiveLayer("indifference");
                        setChartType("Ties Distribution");
                      }}
                    >
                      <div style={{ display: "block", textAlign: "center" }}>
                        <h5 style={{ marginBottom: "32px" }}>
                          Ties Distribution
                        </h5>
                        <p>
                          Understand the distribution of how often alternatives
                          are ranked equally
                        </p>
                        <Icon
                          icon="lucide:chart-column"
                          height={30}
                          color="#E7A339"
                        />
                      </div>
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  );
};

export default Layer1CardMod;
