import { createSlice } from "@reduxjs/toolkit";

export const preferenceSlice = createSlice({
  name: "preference",
  initialState: {
    rawInput: "",
    metaData: [],
    demoMode: true,
  },
  reducers: {
    updateRawInput: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.rawInput = action.payload;
    },
    updateMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    setDemoMode: (state, action) => {
      state.demoMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateRawInput, updateMetaData, setDemoMode } =
  preferenceSlice.actions;

export default preferenceSlice.reducer;
