import { createSlice } from "@reduxjs/toolkit";

export const solutionSlice = createSlice({
  name: "solution",
  initialState: {
    solutions: [],
  },
  reducers: {
    addSolution: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.solutions = state.solutions.push(action.payload);
    },
    clearSolutionList: (state, action) => {
      state.solutions = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateRawInput, updateMetaData } = solutionSlice.actions;

export default solutionSlice.reducer;
