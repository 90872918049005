import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./FileUpload.css";
import DescriptiveStatistic from "./newLayer1/DescriptiveStatisticMod";
import { API } from "aws-amplify";
import Spinner from "react-bootstrap/Spinner";
import jsonData from "./descriptive.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { setDescriptiveData } from "./dataSlice";

const demoFile = `
# PREFERENCE TYPE: toc
# MATCH TYPE: two-sided
# MATCH TYPE: one-one
10,10
1: 1,5,6,3,2,4,7,{8,9,10}
2: 7,2,10,1,3,4,5,6,8,9
3: 3,2,1,4,5,6,7,8,9,10
4: 1,8,4,2,3,5,{6,7,9,10}
5: 3,2,1,4,5,6,7,8,9,10
6: 1,8,4,2,3,5,{6,7,9,10}
7: {3,2,1,4},5,6,7,8,9,10
8: {3,2,1,4},5,6,{7,8,9,10}
9: 1,8,4,2,3,5,6,{7,9,10}
10: 2,3,4,7,8,1,5,6,9,10
1: 1,5,6,3,2,4,7,8,9,10
2: 7,2,10,1,3,4,5,6,{8,9}
3: 3,2,1,4,5,6,7,8,9,10
4: 1,8,4,2,3,5,6,7,9,10
5: 3,2,1,4,5,6,7,{8,9,10}
6: 1,8,4,2,3,5,6,7,9,10
7: {3,2,1,4},5,6,7,8,9,10
8: {3,2,1,4},5,6,{7,8,9,10}
9: 9,5,6,2,3,4,7,8,1,10
10: 1,8,4,2,3,5,6,7,9,10
`;

function FileUpload() {
  const [file, setFile] = useState();
  const [data, setData] = useState([{}]);
  const [show, setShow] = useState(false);
  const [showSpace, setShowSpace] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseSpace = () => setShowSpace(false);
  const handleShowSpace = () => setShowSpace(true);

  const navigate = useNavigate();
  const handleCloseSuccess = () => setSelectedFile(false);
  const handleCloseLoading = () => {
    // setDescriptiveData(null);
    // setSelectedFile(false);
    // setIsLoading(false);
    // navigate("/")
  };
  const handleCloseFile = () => setShowFile(false);

  // const [descriptiveData, setDescriptiveData] = useState(null);
  const descriptiveData = useSelector((state) => {
    console.log(state);
    return state?.data?.descriptiveData;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDemo, setIsLoadingDemo] = useState(false);

  const [contentId, setContentId] = useState(null);
  const [demoBool, setDemoBool] = useState(true);

  const handleDemo = async () => {
    setDescriptiveData(jsonData); // Set the imported data to state
    setSelectedFile(true);
    setIsLoading(false);
    setIsLoadingDemo(false);
    setDemoBool(true);

    // try {
    //   console.log("Uploading demo content");
    //   // setIsLoading(true);
    //   setIsLoadingDemo(true);
    //   console.log("isloading previous", isLoading);

    //   // Use the imported demo content
    //   const uploadResponse = await API.post('matchxplainAPI', '/api/upload', {
    //     body: { content: demoFile }
    //   });
    //   console.log(uploadResponse);
    //   console.log("Demo content uploaded successfully");
    //   const contentId = uploadResponse.content_id;
    //   if (contentId) {
    //     setContentId(contentId);
    //     await fetchDescriptiveData(contentId);
    //   } else {
    //     console.log("No content ID returned from demo");
    //   }
    // } catch (error) {
    //   console.error('Error during demo content upload:', error);
    // }
  };

  const dispatch = useDispatch();

  const fetchDescriptiveData = async (contentId) => {
    try {
      const descriptiveDataResponse = await API.post(
        "matchxplainAPI",
        `/api/descriptive?content_id=${contentId}`
      );
      console.log("Retrieved content:", descriptiveDataResponse);

      // Dispatch action to set descriptive data in Redux store
      dispatch(setDescriptiveData(descriptiveDataResponse.content));

      console.log(
        "Descriptive data set in Redux:",
        descriptiveDataResponse.content
      );
      setSelectedFile(true);
      setIsLoading(false);
      setIsLoadingDemo(false);
    } catch (error) {
      console.error("Error during descriptive data fetch:", error);
    }
  };

  const uploadFile = async (fileContent) => {
    try {
      console.log("Uploading content");
      setIsLoading(true);

      const uploadResponse = await API.post("matchxplainAPI", "/api/upload", {
        body: { content: fileContent },
      });
      console.log(uploadResponse);
      console.log("Content uploaded successfully");
      const contentId = uploadResponse.content_id;
      if (contentId) {
        setContentId(contentId);
        await fetchDescriptiveData(contentId);
        setDemoBool(false);
      } else {
        console.log("No content ID returned");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleFileChange = async (event) => {
    const uploadedFile = event.target.files[0];
    console.log("handle file");

    if (!uploadedFile) {
      return;
    }

    const fileExt = uploadedFile.name.slice(-4);
    const fileNameHasSpaces = /\s+/gm.test(uploadedFile.name);

    if (fileExt !== ".txt" || fileNameHasSpaces) {
      event.target.value = null;

      setShow(fileExt !== ".txt");
      setShowSpace(fileNameHasSpaces);
    } else {
      setShow(false);
      setShowSpace(false);

      const reader = new FileReader();
      reader.readAsText(uploadedFile, "UTF-8");

      reader.onload = async (e) => {
        const fileContent = e.target.result;
        await uploadFile(fileContent);
      };
    }
  };

  function handleUpload() {
    console.log("Navigating to new match summary", demoBool);
    if (contentId) {
      setDemoBool(false);
    }
    if (contentId || demoBool) {
      console.log("Navigating to new match summary", demoBool);
      navigate("/new-ms", {
        state: { contentId: contentId, demoBool: demoBool },
      });
    } else {
      console.log("Content ID not set");
      setShowFile(!selectedFile);
      // Handle the case when contentId is not set
    }
  }

  return (
    <div>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={6} className="d-flex align-items-center">
            <Form.Group
              controlId="formFile"
              onChange={handleFileChange}
              className="mb-3 w-100"
            >
              <Form.Control accept=".txt" type="file" />
            </Form.Group>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip">
                  Upload your preference profile and let MatchXplain reveal the
                  best matchings! Click to check out the{" "}
                  <strong>file format</strong>!
                </Tooltip>
              }
            >
              <a
                href="/file-format"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-2"
                style={{ color: "black", margin: "0" }}
              >
                <Icon
                  icon="fluent:info-32-regular"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>

      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Oops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Make sure to upload preferences as .txt files</Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isLoading && !selectedFile && (
        <Modal show={isLoading} onHide={handleCloseLoading} centered>
          {/* <Modal.Header closeButton>
              <Modal.Title>Welcome to MatchXplain</Modal.Title>
            </Modal.Header> */}
          <Modal.Body style={{ textAlign: "center" }} closeButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{ marginBottom: "1rem", fontSize: "2rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <h5 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Processing...
              </h5>
            </div>
            <br />
            <p style={{ fontSize: "1.2rem" }}>
              Hang tight! Your file is currently being processed...
            </p>
            <p style={{ fontSize: "1.2rem" }}>
              It'll only take a short 5 to 40 seconds journey! 🚀
            </p>
            {/* <FunFacts /> */}
          </Modal.Body>
        </Modal>
      )}
      {isLoadingDemo && !selectedFile && (
        <Modal show={isLoadingDemo} onHide={handleCloseLoading} centered>
          {/* <Modal.Header closeButton>
              <Modal.Title>Welcome to MatchXplain</Modal.Title>
            </Modal.Header> */}
          <Modal.Body style={{ textAlign: "center" }} closeButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{ marginBottom: "1rem", fontSize: "2rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <h5 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Processing...
              </h5>
            </div>
            <br />
            <p style={{ fontSize: "1.2rem" }}>
              Hang tight! Our Demo is loading...
            </p>
            <p style={{ fontSize: "1.2rem" }}>
              It should only take 5 to 40 seconds! 🚀
            </p>
            {/* <FunFacts /> */}
          </Modal.Body>
        </Modal>
      )}

      {selectedFile && !isLoading && !isLoadingDemo && descriptiveData && (
        <DescriptiveStatistic
          isLoading={isLoading}
          show={selectedFile}
          onHide={handleCloseSuccess}
          file={file}
          descriptiveData={descriptiveData}
          handleUpload={handleUpload}
        />
      )}

      {showSpace && (
        <Modal centered show={showSpace} onHide={handleCloseSpace}>
          <Modal.Header closeButton>
            <Modal.Title>Oops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Make sure your filename doesn't contain whitespace
          </Modal.Body>
        </Modal>
      )}
      {showFile && (
        <Modal centered show={showFile} onHide={handleCloseFile}>
          <Modal.Header closeButton>
            <Modal.Title>Oops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>No File Selected!</Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default FileUpload;
