import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaCheck, FaArrowRight } from "react-icons/fa";

function MatchSummaryCard({ matchData, demoBool, propertyData }) {
  const [data, setData] = useState([{}]);
  const [fileName, setFileName] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  function handleClick() {
    navigate("/match-detail", { state: { matchData, demoBool } });
  }

  const generateExplanation = () => {
    let explanationString = "";
    console.log(matchData?.algorithm);
    if (matchData?.algorithm === "weaklyStable") {
      explanationString +=
        "This solution uses Irving's weakly stable algorithm.\n\n";
    }
    if (matchData?.algorithm === "stronglyStable") {
      explanationString +=
        "This solution uses Irving's strongly stable algorithm.\n\n";
    }
    if (matchData?.algorithm === "superStable") {
      explanationString +=
        "This solution uses Irving's super stable algorithm.\n\n";
    }
    if (propertyData?.blockingpairs?.blockingPairCount === 0) {
      explanationString += "Solution eliminates unstable matches. \n";
    } else {
      explanationString += "Solution contains unstable matches. \n";
    }
    if (propertyData?.po?.violations === 0) {
      explanationString += "Solution eliminates exchange cycles. \n";
    } else {
      explanationString += "Solution contains exchange cycles. \n";
    }
    if (propertyData?.envy?.violations === 0) {
      explanationString += "Solution eliminates envy between agents.";
    } else {
      explanationString += "Solution contains envious agents.";
    }
    console.log(explanationString);
    return explanationString;
  };

  return (
    <div>
      <Card className="shadow">
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <Table>
                  <tbody key={"tableBody"}>
                    {typeof propertyData === "undefined" ? (
                      <p>Loading...</p>
                    ) : (
                      matchData.properties?.map((object, i) => {
                        let objectStr = JSON.stringify(object);
                        let firstKey = Object.keys(object);
                        let show = Object.values(object);
                        return (
                          <tr key={firstKey + "list"}>
                            <td key={i}>
                              {firstKey == "Pareto Optimal"
                                ? "Efficient"
                                : firstKey == "Envy Free"
                                ? "Fair"
                                : "Stable"}
                            </td>
                            {(firstKey == "Pareto Optimal" &&
                              propertyData.po.violations !== 0) ||
                            (firstKey == "Envy Free" &&
                              propertyData.envy.violations !== 0) ||
                            (firstKey == "Stable" &&
                              propertyData.blockingpairs.blockingPairCount !==
                                0) ? (
                              <td key={firstKey + "check"}></td>
                            ) : (
                              <td key={firstKey}>
                                <FaCheck />
                              </td>
                            )}
                            {/* {firstKey == "Pareto Optimal"
                              ? (propertyData.po.violations !== 0 ? )"Efficient"
                              : firstKey == "Envy Free"
                              ? "Fair"
                              : "Stable"}
                          {show[0] ? (
                            <td key={firstKey}>
                              <FaCheck />
                            </td>
                          ) : (
                            <td key={firstKey + "check"}></td>
                          )} */}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col>
                {/* <p>{matchData.explanation}</p> */}
                {typeof propertyData === "undefined" ? (
                  <p>Loading...</p>
                ) : (
                  <p>{generateExplanation()}</p>
                )}
                <Button
                  onClick={handleClick}
                  className="float-right"
                  variant="outline-primary"
                >
                  View Solution <FaArrowRight />
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MatchSummaryCard;
