import {React, useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import BordaStackedChart from './BordaStackedChart';
import RankBarChart from './RankBarChart';
import BordaDistribution from './BordaDistirbution';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import MutualLike from './MutualLike';
import Accordion from 'react-bootstrap/Accordion';
import './Table.css';
import "./Layer1Card.css";

function InfoTooltip(props) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip">
            {props.tooltipText}
          </Tooltip>
        }
      >
        <span><Icon icon="fluent:info-28-regular" /></span>
      </OverlayTrigger>
    );
  }

  function InfoTableRow({ title, tooltipText, value, suffix }) {
    const stringValue = String(value);
    const hasParentheses = stringValue.includes('(');
    const [mainValue, iqrValue] = hasParentheses ? stringValue.split(' (') : [stringValue, null];
    const formattedIqrValue = iqrValue ? '(' + iqrValue : null;

    return (
        <tr>
            <td className="title-cell">
                {title}
                <InfoTooltip tooltipText={tooltipText} />
            </td>
            <td className="value-cell">
                {mainValue} {suffix ? <>({suffix})</> : null}
                {formattedIqrValue && <div className="iqr-value">{formattedIqrValue}</div>}
            </td>
        </tr>
    );
}
  
  
  

const PopularityAdvance = ({descriptiveData, onUpdateActiveLayer, currentLayer, handleUpload}) => {


    const [chartType, setChartType] = useState("Popularity Score");


    const [showAgentInfo, setShowAgentInfo] = useState(false);
    const [agentNumber, setAgentNumber] = useState();
    

    const handleNumberChange = (event) => {
        setAgentNumber(event.target.value);
    };
    


    const cardStyle = {
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
        transition: '0.3s',
    };
    const cardStyle1 = {
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1)',
        transition: '0.3s',
        borderRadius: '0px',
        border: 'none',
    }
    const [isPessimalChecked, setPessimalChecked] = useState(false);

    // Input information: Two-Sides, One-to-One, TOC
    const inputInfo = descriptiveData ? descriptiveData.input_info : null;
    const problemTypes = inputInfo && inputInfo.problem_type ? inputInfo.problem_type.split(",") : [];
    const preferenceTypes = inputInfo && inputInfo.preference_type ? inputInfo.preference_type.split(",") : [];

    const level1Info = descriptiveData ? descriptiveData.level1_info : null;
    const [isDataLoaded, setIsDataLoaded] = useState(false);



    const maxAgents = 12; 

    const handleFormSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        // if (Number(agentNumber) < 1 || Number(agentNumber) > maxAgents) {
        //     alert("Invalid agent number. Please enter a number between 1 and " + maxAgents);
        // } else {
            console.log('Form supmitted', agentNumber);
            setShowAgentInfo(true);
        // }
    };
    
    
    

    const [activeTab, setActiveTab] = useState('proposer');
    const [activeSide, setActiveSide] = useState([]);
    const [inactiveSide, setInactiveSide] = useState([]);

    useEffect(() => {
        if (activeTab === 'proposer') {
            setActiveSide('A');
            setInactiveSide('B');
          } else if (activeTab === 'proposee') {
            setActiveSide('B');
            setInactiveSide('A');
          }
    }, [activeTab]);

    const handleTabSelect = (key) => {
      setActiveTab(key);
    };


    function ordinalSuffix(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return <>{i}<sup>st</sup></>;
        }
        if (j == 2 && k != 12) {
            return <>{i}<sup>nd</sup></>;
        }
        if (j == 3 && k != 13) {
            return <>{i}<sup>rd</sup></>;
        }
        return <>{i}<sup>th</sup></>;
    }
    

    var rank = level1Info[activeTab].borda_score_stack.total.slice().sort((a, b) => b - a).indexOf(level1Info[activeTab].borda_score_stack.total[String(+agentNumber - 1)]) + 1;

    const explanationMapping = {
        'Popularity Score': [
            {
                title: 'What is the Popularity Score?',
                body: (
                  <>
                    <strong>Scenario:</strong> Think of a school event where clubs (the current side) and students (the other side) are looking to match up.<br/>
                    <strong>Ranking System:</strong> Students rank clubs; higher ranks yield more scores.<br/>
                    <strong>Popularity Score:</strong> Total of all scores a club receives from students.<br/>
                    <strong>Goal:</strong> Gauges clubs' popularity from students' viewpoint.
                  </>
                )
            },
    
            {
                title: 'How to Interpret the Bar Chart?',
                body: (
                  <>
                    <strong>Goal:</strong> To visually compare club popularity among students.<br/>
                    <strong>Visual:</strong> Like a leaderboard for clubs.<br/>
                    <strong>Bar:</strong> Represents the club's Popularity Score.<br/>
                    <strong>Interpretation:</strong> Longer bars = higher popularity among students.
                  </>
                )
            },
            {
                title: 'How to Interpret Segments in the Bar?',
                body: (
                  <>
                    <strong>Division:</strong> Student ranks are split into quartiles (Q1-Q4).<br/>
                    <strong>Q1 (Darkest Segment):</strong> Points from the club's biggest fans (top 25% of ranks).<br/>
                    <strong>Other Segments:</strong> Each subsequent segment (Q2-Q4) represents lower-ranking quartiles.<br/>
                    <strong>Goal:</strong> Demonstrates strength of a club’s support across different ranking levels.
                  </>
                )
            },
            {
                title: 'How to Interact with the Chart?',
                body: (
                  <>
                    <strong>Hover:</strong> Reveals scores and student numbers for that quarter.<br/>
                    <strong>Click:</strong> Opens detailed information about the club.<br/>
                    <strong>Legend Manipulation:</strong> Clicking a quarter re-order popularity excluding that quarter’s scores.
                  </>
                )
            },
        ],

        'Rank Distribution Heatmap': [
            {
                title: 'Rank Distribution Heatmap Explained:',
                body: (
                  <>
                    <strong>Scenario:</strong> Think of a school event where clubs (the current side) and students (the other side) are looking to match up.<br/>
                    <strong>Rows:</strong> Alternatives (Clubs) - the current side<br/>
                    <strong>Cols:</strong> Ranks 1 through N (number of alternatives).<br/>
                    <strong>Value:</strong> Shows how often clubs are ranked in each position by students (the value might contain decimals due to ill-structured preference).<br/>
                    <strong>Color Scale:</strong> Varies from light to dark; darker shades indicate higher frequency of being ranked in that spot.<br/>
                    <strong>Goal:</strong> To reveal the precise frequency distribution of ranks for clubs.
                  </>
                )
            },
            
        ],
        'Mutual Like Heatmap': [
            {
                title: 'Mutual Like Heatmap Explained:',
                body: (
                  <>
                    <strong>Scenario:</strong> Think of a school event where clubs (the current side) and students (the other side) are looking to match up.<br/>
                    <strong>Rows:</strong> Alternatives (Clubs) - the current side<br/>
                    <strong>Cols:</strong> Ranks 1 through N (number of alternatives).<br/>
                    <strong>Value:</strong> Reflect students' mutual liking for clubs at each rank.<br/>
                    <strong>Color Scale:</strong> The color gradient (red to green) shows like intensity, with green signifying stronger mutual interest.<br/>
                    <strong>Goal:</strong> Identify and highlight mutual connections for potential matches.
                  </>
                )
            }
        ]
      };
      
    const [explanations, setExplanations] = useState(explanationMapping['Popularity Score']); 
    
    
    const handleChartTypeChange = (newChartType) => {
        setChartType(newChartType);
        setExplanations(explanationMapping[newChartType]);
    };

    const [individualView, setIndividualView] = useState(false);

    const handleBarClick = (altNumber) => {
        console.log("Bar Clicked in Parent Component:", `Alt ${altNumber}`);
        
        setAgentNumber(altNumber);
   
    handleFormSubmit();
    };
    useEffect(() => {
        if (agentNumber) { 
            setIndividualView(true);
        }
    }, [agentNumber]);


    const [topSliceValue, setTopSliceValue] = useState(10); 
    const [expanded, setExpanded] = useState(false);




    return (
        descriptiveData && (
            <Container fluid style={{width:" 95%"}}>

                <Row style={{marginTop:"1rem", marginBottom:'1rem'}}>
                    <Col md={4}>
                        <Button style={{padding:'1rem', width:'15rem'}} onClick={() => onUpdateActiveLayer('layer1card')} variant="outline-secondary"><FaArrowLeft /> Back to Overview</Button>
                    </Col>
                    <Col md={4} style={{display: 'flex', marginLeft:'-20px',marginRight:'20px',  textAlign:'center', alignItems:'center', justifyContent:'center'}}>
                        <h2 >Popularity</h2>
                    </Col>
                    <Col md={4} style={{textAlign:'end'}}>
                        <Button onClick={handleUpload} variant="dark"  style={{padding:'1rem', width:'18rem'}}>
                            Continue: Recommend Solutions <Icon icon="bytesize:arrow-right" />
                        </Button>
                    </Col>
                </Row>

                <Row  style={{display:'flex', textAlign:'center', alignContent:'center', justifyContent:'center', alignItems:'center'}}>
                    {/* <Col md={2}>
                        <h4>Methods: </h4>
                    </Col> */}
                    <Col md={12}> 
                        <div className='section-div'>
                            <Row>
                            <Col md={4}>
                                <div className={`section ${chartType === 'Popularity Score' ? 'active-section' : ''}`} onClick={() => handleChartTypeChange('Popularity Score')}>
                                    <h4 className="sectionTitle">Popularity Score</h4>
                                    <p className="sectionContent">Ranks alternatives from the highest popularity score to the lowest</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`section ${chartType === 'Mutual Like Heatmap' ? 'active-section' : ''}`} onClick={() => handleChartTypeChange('Mutual Like Heatmap')}>
                                    <h4 className="sectionTitle">Mutual Like Heatmap</h4>
                                    <p className="sectionContent">Lets you see if the ones alternatives like also like them back</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={`section ${chartType === 'Rank Distribution Heatmap' ? 'active-section' : ''}`} onClick={() => handleChartTypeChange('Rank Distribution Heatmap')}>
                                    <h4 className="sectionTitle">Rank Distribution Heatmap</h4>
                                    <p className="sectionContent">A matrix table that summarizes the ranking position for each alternative</p>
                                </div>
                            </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={1}>
                        <Tabs 
                            activeKey={activeTab} 
                            onSelect={handleTabSelect} 
                            id="justify-tab-example"
                            className="mb-3"
                            justify
                        >
                        <Tab 
                        eventKey="proposer" 
                        title={<span className="rotate-text">Side A</span>} 
                        className={activeTab === 'proposer' ? 'active' : ''}
                        >
                        </Tab>

                        <Tab 
                        eventKey="proposee" 
                        title={<span className="rotate-text">Side B</span>} 
                        className={activeTab === 'proposee' ? 'active' : ''}
                        >
                        </Tab>


                        </Tabs>
                    </Col>

                    <Col xs={12} md={11}>
                        <div className='tab-wrapper'>
                            <Row>
                                <Col xs={12} md={8}>
                                    <Card className="mb-4" style={cardStyle1}>
                                        <Card.Body>
                                            <>
                                                <div style={{display:"flex", justifyContent:'space-between'}}>
                                                    <Form.Check
                                                        type="switch"
                                                        id="individual-view-switch"
                                                        label="Individual View"
                                                        checked={individualView} 
                                                        onChange={(e) => setIndividualView(e.target.checked)}
                                                        style={{ marginBottom: '-29px', marginTop: '6px'}}
                                                    />
                                                    <div style={{display:'flex', justifyContent:'center', flexDirection:'row', height:'50px', alignItems: 'center'}}>
                                                        <h3>{chartType}</h3>
                                                    </div>

                                                    <div style={{ display:"flex", flexDirection:'row', marginTop:'-10px', marginRight:'5px', visibility: chartType === "Popularity Score" ? 'visible' : 'hidden' }}>
                                                        <Form style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Form.Label className="mr-2" style={{ marginBottom: '0' }}>Top</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={topSliceValue}
                                                                onChange={(e) => {
                                                                
                                                                    const val = parseInt(e.target.value, 10);
                                                                    if (val > 0) {
                                                                        setTopSliceValue(val);
                                                                    }
                                                                }}
                                                                style={{ width: "80px", display: 'inline-block', marginBottom: '0' }} 
                                                            />
                                                            <span className="ml-2">Alts</span>
                                                        </Form>
                                                    </div>
                                                </div>
                                                    <Card.Text>
                                                            <div key={`${chartType}-${activeTab}`} style={{ position: "relative", height: "520px" }}>
                                                                {level1Info && chartType === "Popularity Score" && (
                                                                <>
                                                                    <BordaStackedChart
                                                                        data={
                                                                            activeTab === "proposer"
                                                                                ? (isPessimalChecked ? level1Info.proposer.borda_score_stack_pessimal : level1Info.proposer.borda_score_stack)
                                                                                : (isPessimalChecked ? level1Info.proposee.borda_score_stack_pessimal : level1Info.proposee.borda_score_stack)
                                                                        }
                                                                        currentTab={activeTab}
                                                                        onBarClick={handleBarClick}
                                                                        topSlice={topSliceValue} 
                                                                    />
                                                                </>
                                                            )}
                                                    
                                                                {chartType === "Rank Distribution Heatmap" && (
                                                                    
                                                                    <BordaDistribution 
                                                                        key={activeTab}
                                                                        data={
                                                                            activeTab === "proposer"
                                                                            ? level1Info.proposer.borda_score_stack.heatmap
                                                                            : level1Info.proposee.borda_score_stack.heatmap
                                                                        }
                                                                        currentTab={activeTab}
                                                                        type="rank distribution"
                                                                    />
                                                                )}

                                                                {chartType === "Mutual Like Heatmap" && (
                                                                    <>
                                                                        {/* <Form style={{textAlign:"right"}}>
                                                                            <Form.Check
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            label="Pessimal"
                                                                            onChange={(e) => setPessimalChecked(e.target.checked)} // this line updates the state when the switch is toggled
                                                                            />
                                                                        </Form> */}
                                                                        <MutualLike 
                                                                            key={[activeTab, isPessimalChecked]}
                                                                            data={
                                                                                activeTab === "proposer"
                                                                                ? (isPessimalChecked ? level1Info.proposer.mutual_like_pessimal : level1Info.proposer.mutual_like)
                                                                                : (isPessimalChecked ? level1Info.proposee.mutual_like_pessimal : level1Info.proposee.mutual_like)
                                                                            }
                                                                            currentTab={activeTab}
                                                                            type="mutual like"
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>

                                                            

                                                    </Card.Text>

                                                </>
                            
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={12} md={4}>
                                    <Card  className="mb-4" style={cardStyle1}>
                                        <Card.Body>
                                            <Card.Text>
                                                    <div className='section-div' style={{ position: "relative", height: "570px"}}>
                                                        <Accordion defaultActiveKey="0" flush className="custom-accordion">

                                                            {individualView ? (
                                                        <>
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', height: '50px', marginBottom:'1rem', marginTop:'2rem'}}>
                                                            <Form style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }} onSupmit={handleFormSubmit}>
                                                            <Form.Group style={{ justifyContent: 'center', display: 'flex', gap: '1rem', flexDirection:'row', textAlign:'center' }} controlId="formAgentNumber">
                                                                <Form.Label><Card.Title><h3>For Alt:</h3></Card.Title></Form.Label>
                                                                <Form.Control style={{ maxWidth: '100px', textAlign: 'center', justifyContent: 'center' }} type="number" value={agentNumber || ''} placeholder="number" onChange={handleNumberChange} />
                                                            </Form.Group>
                                                            </Form>
                                                        </div>
                                                        <Card.Text>
                                                            <div style={{ position: "relative", height: "408px", margin:'1rem'}}>
                                                            {level1Info && level1Info[activeTab].borda_score_stats && (
                                                                    <div>
                                                                    <table className="styled-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: '70%' }}>Statistic</th>
                                                                            <th style={{ width: '30%' }}>Value</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <InfoTableRow 
                                                                            title="Popularity Score" 
                                                                            tooltipText="The Popularity Score is the sum of the positions this alternative holds in all of the other side's alternatives' preference lists. A higher score means this alternative is generally preferred." 
                                                                            value={level1Info[activeTab].borda_score_stack.total[String(+agentNumber - 1)] || 'N/A'} 
                                                                            suffix={ordinalSuffix(rank)}
                                                                        />
                                                                        <InfoTableRow 
                                                                            title="Average Ranked by B as" 
                                                                            tooltipText="This is the average position that the other side's alternatives assigned to this alternative in their preference lists. A lower number means this alternative is generally ranked higher." 
                                                                            value={level1Info[activeTab].borda_score_stats['Mean Rank'][String(agentNumber)] || 'N/A'}
                                                                        />
                                                                        {expanded && (
                                                                            <>
                                                                        <InfoTableRow 
                                                                            title="Median Ranked by B as" 
                                                                            tooltipText="This is the middle position that the other side's alternatives assigned to this alternative when all the rankings are lined up in numerical order." 
                                                                            value={level1Info[activeTab].borda_score_stats['Median Rank'][String(agentNumber)] || 'N/A'}
                                                                        />
                                                                        <InfoTableRow 
                                                                            title="Mode Ranked by B as" 
                                                                            tooltipText="This is the position that this alternative most frequently holds in the other side's alternatives' preference lists." 
                                                                            value={level1Info[activeTab].borda_score_stats['Mode Rank'][String(agentNumber)] 
                                                                                    ? level1Info[activeTab].borda_score_stats['Mode Rank'][String(agentNumber)].slice(0, 5).join(', ') +
                                                                                    (level1Info[activeTab].borda_score_stats['Mode Rank'][String(agentNumber)].length > 5 ? ', ...' : '')
                                                                                    : 'N/A'}
                                                                        />
                                                                        <InfoTableRow 
                                                                            title="Ranking Position Range" 
                                                                            tooltipText="This shows the highest and lowest positions this alternative holds across all of the other side's alternatives' preference lists." 
                                                                            value={level1Info[activeTab].borda_score_stats['Range of Rank'][String(agentNumber)] || 'N/A'}
                                                                        />
                                                                        <InfoTableRow 
                                                                            title="Unranked Occurrences" 
                                                                            tooltipText="This is the number of times this alternative was not ranked in the other side's alternatives' preference lists." 
                                                                            value={level1Info[activeTab].borda_score_stats['Truncated Size'][String(agentNumber)] || '0'}
                                                                        />
                                                                        </>
                                                                        )}
                                                                    </tbody>
                
                                                                    </table>
                                                                    <div style={{ textAlign: 'center' }}>
                                                                    <i
                                                                        className={`fa fa-chevron-${expanded ? 'up' : 'down'}`}
                                                                        onClick={() => setExpanded(!expanded)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                    </div>
                                                                    {!expanded && level1Info[activeTab].borda_score_stack.distribution[agentNumber - 1] && (
                                                                    <RankBarChart data={level1Info[activeTab].borda_score_stack.distribution[agentNumber - 1]} />
                                                                    )}
                                                                    </div>
                                                            )}
                                                            </div>
                                                        </Card.Text>
                                                    </>
                                                            ) : (
                                                                <>    
                                                                    {explanations.map((explanation, index) => (
                                                                        <Accordion.Item eventKey={index.toString()}>
                                                                        <Accordion.Header>{explanation.title}</Accordion.Header>
                                                                        <Accordion.Body>{explanation.body}</Accordion.Body>
                                                                        </Accordion.Item>
                                                                    ))}
                                                                    <div style={{ 
                                                                        position: 'absolute', 
                                                                        bottom: '0', 
                                                                        width: '100%', 
                                                                        textAlign: 'center',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center', 
                                                                        marginBottom: '5px', 
                                                                        padding: '5px' 
                                                                    }}>
                                                                        <strong>For more insights:</strong> <a 
                                                                            href="/learn#topic1-sub1" 
                                                                            target="_blank" 
                                                                            rel="noopener noreferrer" 
                                                                            style={{ textDecoration: 'underline', color: '#007BFF' }}
                                                                        >
                                                                            Explore Learn
                                                                        </a>
                                                                    </div>

                                                                </>
                                                            )}
                                                        </Accordion>
                                                    </div>
                                            
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {/* View Next */}
                {/* <Row style={{marginTop:"2rem", marginBottom:'1rem'}}>
                    <Col style={{display:'flex', justifyContent:'end'}}>
                        <Button style={{padding:'1rem', width:'15rem'}} onClick={() => onUpdateActiveLayer('similarity')} variant="dark">View next: Similarity <Icon icon="bytesize:arrow-right" /></Button>
                    </Col>
                </Row> */}
                
            

            </Container>

        )
    );
};

export default PopularityAdvance;
