import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Scatter,
  CartesianGrid,
  Label,
} from "recharts";
import Container from "react-bootstrap/esm/Container";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { API } from 'aws-amplify';

function RadarCompare() {
  const location = useLocation();
  const [compData, SetCompData] = useState([{}]);
  const [compData2, SetCompData2] = useState([{}]);
  const matchSummary = location.state.data;
  console.log(matchSummary.matches[0]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            // First API call with matchSummary.matches[0]
            const apiData = await API.post('matchxplainAPI', '/api/match-detail', {
                body: { data: matchSummary.matches[0] }
            });
            SetCompData(apiData);
            console.log(apiData.properties.envy.numberViolators);
            console.log(matchSummary.matches[0].metaData[1]);

            // Second API call with matchSummary.matches[1]
            const apiData2 = await API.post('matchxplainAPI', '/api/match-detail', {
                body: { data: matchSummary.matches[1] }
            });
            SetCompData2(apiData2);
            console.log(apiData2.properties);
            console.log(matchSummary.matches[1].metaData[1]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    if (matchSummary && matchSummary.matches && matchSummary.matches.length >= 2) {
        fetchData();
    } else {
        console.log('Insufficient match data available');
    }
}, [matchSummary, SetCompData, SetCompData2]);

  //  (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2] - compData.properties.envy.numberViolators)/(matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[1])
  // {(typeof compData === 'undefined') ? (
  //   <p>Loading...</p>
  // ) : (console.log(compData))}

  // const envy1 = (matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[2] - compData.properties.envy.numberViolators)/(matchSummary.matches[0].metaData[1] + matchSummary.matches[0].metaData[1]);
  return (
    <div>
      <Container>
        <Row>
          <Col lg={4}>
            <h1>Your Matchings</h1>
            <p>
              We've generated a couple of options for your ordinal preference
              profile. Click on any of the data points to view the details of
              the respective matching, or hover to learn more.
            </p>
          </Col>
          <Col lg={8}>
            <Card className="shadow">
              <Card.Body>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ResponsiveContainer width="90%" height={600}>
                    {typeof compData?.properties?.envy === "undefined" &&
                    typeof compData2?.properties?.envy === "undefined" ? (
                      <p>Loading...</p>
                    ) : (
                      <ScatterChart
                        //   width="100%"
                        //   height={700}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 30,
                          left: 10,
                        }}
                      >
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis
                          dataKey="x"
                          type="number"
                          name="Agents in blocking pairs"
                          unit="%"
                        >
                          <Label
                            style={{
                              textAnchor: "middle",
                              paddingBottom: 500,
                            }}
                            position="bottom"
                            value={"% of Agents in Blocking Pairs"}
                          />
                        </XAxis>
                        <YAxis
                          dataKey="y"
                          type="number"
                          name="Unenvious agents"
                          unit="%"
                        >
                          <Label
                            style={{
                              textAnchor: "middle",
                            }}
                            position="left"
                            angle={270}
                            value={"% of Unenvious Agents"}
                          />
                        </YAxis>
                        {/* <ZAxis
            dataKey="z"
            type="number"
            range={[64, 144]}
            name="Agents in blocking pairs"
            unit="%"
          /> */}
                        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                        <Legend
                          height={36}
                          wrapperStyle={{ position: "relative" }}
                        />
                        <Scatter
                          name="Gale-Shapley"
                          data={[
                            {
                              x:
                                ((matchSummary.matches[0].metaData[1] +
                                  matchSummary.matches[0].metaData[2] -
                                  compData?.properties?.blockingpairs
                                    .agentsInvolved) /
                                  (matchSummary.matches[0].metaData[1] +
                                    matchSummary.matches[0].metaData[2])) *
                                100,
                              y:
                                ((matchSummary.matches[0].metaData[1] +
                                  matchSummary.matches[0].metaData[2] -
                                  compData?.properties?.envy.numberViolators) /
                                  (matchSummary.matches[0].metaData[1] +
                                    matchSummary.matches[0].metaData[2])) *
                                100,
                              // z: 200,
                            },
                          ]}
                          fill="#8884d8"
                          scale={500}
                        />
                        <Scatter
                          name="Top Trading Cycle"
                          data={[
                            {
                              x:
                                ((matchSummary.matches[1].metaData[1] +
                                  matchSummary.matches[1].metaData[2] -
                                  compData2?.properties?.blockingpairs
                                    .agentsInvolved) /
                                  (matchSummary.matches[1].metaData[1] +
                                    matchSummary.matches[1].metaData[2])) *
                                100,
                              y:
                                ((matchSummary.matches[1].metaData[1] +
                                  matchSummary.matches[1].metaData[2] -
                                  compData?.properties?.envy.numberViolators) /
                                  (matchSummary.matches[1].metaData[1] +
                                    matchSummary.matches[1].metaData[2])) *
                                100,
                              // z: 200,
                            },
                          ]}
                          fill="#82ca9d"
                        />
                      </ScatterChart>
                    )}
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <br />
    </div>

    // <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    //   <ResponsiveContainer width="60%" height={600}>
    //     {typeof compData?.properties?.envy === "undefined" &&
    //     typeof compData2?.properties?.envy === "undefined" ? (
    //       <p>Loading...</p>
    //     ) : (
    //       <ScatterChart
    //         //   width="100%"
    //         //   height={700}
    //         margin={{
    //           top: 20,
    //           right: 20,
    //           bottom: 30,
    //           left: 10,
    //         }}
    //       >
    //         {/* <CartesianGrid strokeDasharray="3 3" /> */}
    //         <XAxis
    //           dataKey="x"
    //           type="number"
    //           name="Agents in blocking pairs"
    //           unit="%"
    //         >
    //           <Label
    //             style={{
    //               textAnchor: "middle",
    //               paddingBottom: 500,
    //             }}
    //             position="bottom"
    //             value={"% of Agents in Blocking Pairs"}
    //           />
    //         </XAxis>
    //         <YAxis dataKey="y" type="number" name="Unenvious agents" unit="%">
    //           <Label
    //             style={{
    //               textAnchor: "middle",
    //             }}
    //             position="left"
    //             angle={270}
    //             value={"% of Unenvious Agents"}
    //           />
    //         </YAxis>
    //         {/* <ZAxis
    //         dataKey="z"
    //         type="number"
    //         range={[64, 144]}
    //         name="Agents in blocking pairs"
    //         unit="%"
    //       /> */}
    //         <Tooltip cursor={{ strokeDasharray: "3 3" }} />
    //         <Legend height={36} wrapperStyle={{ position: "relative" }} />
    //         <Scatter
    //           name="Gale-Shapley"
    //           data={[
    //             {
    //               x:
    //                 ((matchSummary.matches[0].metaData[1] +
    //                   matchSummary.matches[0].metaData[2] -
    //                   compData?.properties?.blockingpairs.agentsInvolved) /
    //                   (matchSummary.matches[0].metaData[1] +
    //                     matchSummary.matches[0].metaData[2])) *
    //                 100,
    //               y:
    //                 ((matchSummary.matches[0].metaData[1] +
    //                   matchSummary.matches[0].metaData[2] -
    //                   compData?.properties?.envy.numberViolators) /
    //                   (matchSummary.matches[0].metaData[1] +
    //                     matchSummary.matches[0].metaData[2])) *
    //                 100,
    //               // z: 200,
    //             },
    //           ]}
    //           fill="#8884d8"
    //         />
    //         <Scatter
    //           name="Top Trading Cycle"
    //           data={[
    //             {
    //               x:
    //                 ((matchSummary.matches[1].metaData[1] +
    //                   matchSummary.matches[1].metaData[2] -
    //                   compData2?.properties?.blockingpairs.agentsInvolved) /
    //                   (matchSummary.matches[1].metaData[1] +
    //                     matchSummary.matches[1].metaData[2])) *
    //                 100,
    //               y:
    //                 ((matchSummary.matches[1].metaData[1] +
    //                   matchSummary.matches[1].metaData[2] -
    //                   compData?.properties?.envy.numberViolators) /
    //                   (matchSummary.matches[1].metaData[1] +
    //                     matchSummary.matches[1].metaData[2])) *
    //                 100,
    //               // z: 200,
    //             },
    //           ]}
    //           fill="#82ca9d"
    //         />
    //       </ScatterChart>
    //     )}
    //   </ResponsiveContainer>
    // </div>
  );
}

export default RadarCompare;
