import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaCheck, FaArrowLeft, FaRegCircle, FaCircle } from "react-icons/fa";
import Flow2 from "../components/ReactFlowRedesign";
import LargeInstanceFlow from "../components/LargeInstanceFlow";
import { API } from "aws-amplify";
import jsonData from "./matchdetailupdate.json";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";

const OptionSwitch = ({ name, tooltip, state, setState }) => {
  return (
    <div className="switch-div">
      <Tippy content={tooltip}>
        <span className="info-span" role="button">
          <Icon
            icon={"lucide:info"}
            width="18"
            // height="23"
            // color="#ff582e"
          />
        </span>
      </Tippy>
      <p style={{ flexGrow: 1, marginLeft: "10px", marginRight: "10px" }}>
        {name}
      </p>
      <Form.Group>
        <Form.Check // prettier-ignore
          //   disabled={true}
          type={"switch"}
          id="custom-switch"
        >
          <Form.Check.Input
            type={"checkbox"}
            defaultChecked={state}
            onClick={(e) => {
              setState((state) => !state);
            }}
          />
        </Form.Check>
      </Form.Group>
    </div>
  );
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {};

const labels = [
  "Unstable Agents",
  "Weakly Stable Agents",
  "Strongly Stable Agents",
  "Super Stable Agents",
];
const dstest = {
  labels: labels,
  datasets: [
    {
      data: [65, 10, 20],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 205, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
      ],
      borderColor: [
        "rgb(255, 99, 132)",
        "rgb(255, 205, 86)",
        "rgb(75, 192, 192)",
      ],
      borderWidth: 1,
    },
  ],
};

const stabilityData = (propertyData, n) => {
  console.log("IN STABIILITY DATA");
  const notStableCount = propertyData.blockingpairs.agentsInvolved;
  const weakCount =
    propertyData.strongblockingpairs.agentsInvolved - notStableCount;
  const strongCount =
    propertyData.strongblockingpairs.agentsInvolved - notStableCount;
  const superCount =
    n - propertyData.strongblockingpairs.agentsInvolved - strongCount;

  console.log([
    (notStableCount * 100) / n,
    ((weakCount - weakCount) * 100) / n,
    ((strongCount - weakCount) * 100) / n,
    ((superCount - weakCount) * 100) / n,
  ]);

  return {
    labels: labels,
    datasets: [
      {
        data: [
          (notStableCount * 100) / n,
          ((weakCount - weakCount) * 100) / n,
          ((strongCount - weakCount) * 100) / n,
          ((superCount - weakCount) * 100) / n,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

function MatchDetail2024() {
  const location = useLocation();
  const matchData = location.state.matchData;
  const demoBool = location.state.demoBool;
  const [data, setData] = useState([{}]);
  const [bpButton, setbpButton] = useState("primary");
  const [envyButton, setEnvyButton] = useState("outline-primary");
  const [matchButton, setMatchButton] = useState("outline-primary");
  const [pieData, setPieData] = useState({
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: [0, 0, 0, 0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  });

  //   useEffect(()=> {
  //     if (data !== [{}]) {

  //     }
  //   }, [data])

  // let showEnvy = false;

  const [showBP, setShowBP] = useState(false);
  const [showStrongBP, setShowStrongBP] = useState(false);
  const [showSuperBP, setShowSuperBP] = useState(false);
  const [showEnvy, setShowEnvy] = useState(false);
  const [showMatch, setShowMatch] = useState(true);
  const [envyInfo, setEnvyInfo] = useState(
    "Click on a node to see that agent's envy"
  );
  const [envyVis, setEnvyVis] = useState(
    Array(matchData.metaData[1] + matchData.metaData[2]).fill(true)
  );
  const [selectInfo, setSelectInfo] = useState(
    Array(matchData.metaData[1] + matchData.metaData[2]).fill(false)
  );

  const wrapperSetParentState = useCallback(
    (val) => {
      setEnvyVis(val);
    },
    [setEnvyVis]
  );

  const wrapperSetSelectState = useCallback(
    (val) => {
      setSelectInfo(val);
    },
    [setEnvyVis]
  );

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleShowBP = () => {
    setShowBP(!showBP);
    console.log(showBP);
    if (bpButton == "outline-primary") {
      setbpButton("primary");
    } else {
      setbpButton("outline-primary");
    }
  };

  const handleShowEnvy = () => {
    setShowEnvy(!showEnvy);
    console.log(showEnvy);
    if (envyButton == "outline-primary") {
      setEnvyButton("primary");
    } else {
      setEnvyButton("outline-primary");
    }
  };

  const handleShowMatch = () => {
    setShowMatch(!showMatch);
    console.log(showMatch);
    if (matchButton == "outline-primary") {
      setMatchButton("primary");
    } else {
      setMatchButton("outline-primary");
    }
    console.log(JSON.stringify(data));
  };

  function generateExplanation() {
    var output = "Loading...";
    if (data.properties?.blockingpairs?.blockingPairCount > 1) {
      output = `This solution contains ${data.properties?.blockingpairs?.blockingPairCount} blocking pairs, meaning that the matching is unstable.`;
    } else if (data.properties?.blockingpairs?.blockingPairCount == 1) {
      output = `This solution contains ${data.properties?.blockingpairs?.blockingPairCount} blocking pair, meaning that the matching is unstable.`;
    } else {
      output = `This solution doesn’t contain any blocking pairs, making this matching stable.`;
    }

    if (data.properties?.envy?.numberViolators > 1) {
      output = output.concat(
        ` ${data.properties?.envy?.numberViolators} agents are envious of other agents, meaning that this matching isn’t fair.`
      );
    } else if (data.properties?.envy?.numberViolators == 1) {
      output = output.concat(
        ` ${data.properties?.envy?.numberViolators} agent is envious of other agents, meaning that this matching isn’t fair.`
      );
    } else {
      output = output.concat(
        ` Additionally, there aren’t any instances of envy between agents, making this matching fair.`
      );
    }

    if (data.properties?.po?.violations > 1) {
      output = output.concat(
        ` Since there are ${data.properties?.po?.violations} Pareto violators in this solution, this matching isn’t efficient.`
      );
    } else if (data.properties?.po?.violations == 1) {
      output = output.concat(
        ` Since there is ${data.properties?.po?.violations} Pareto violator in this solution, this matching isn’t efficient`
      );
    } else {
      output = output.concat(
        ` Since there aren’t any Pareto violations in this solution, this matching is efficient.`
      );
    }
    return output;
  }

  {
    /* <td key={2}>{data.properties?.?.violations}</td>
                            <td key={2}>
                              {data.properties?.envy?.numberViolators}
                            </td>
                            <td key={2}>{data.properties?.po?.violations}</td> */
  }

  // const showEnvyFunc = () => {
  //     console.log('Click happened');
  //     return {showEnvy}
  //   };

  useEffect(() => {
    const fetchData = async () => {
      console.log("this is demobool", demoBool);
      if (demoBool) {
        setData(jsonData[matchData.algorithm === "ttc" ? 1 : 0]);
        setPieData(
          stabilityData(
            jsonData[matchData.algorithm === "ttc" ? 1 : 0].properties,
            matchData.metaData[1] + matchData.metaData[2]
          )
        );
        console.log("this is the demo data", jsonData);
      } else {
        try {
          console.log("fetch from match-detail");
          const apiData = await API.post(
            "matchxplainAPI",
            "/api/match-detail",
            {
              body: { data: matchData },
            }
          );
          setData(apiData);
          console.log(JSON.stringify(apiData));
          console.log("this should be the real dataformat", apiData);
          setPieData(
            stabilityData(
              apiData.properties,
              matchData.metaData[1] + matchData.metaData[2]
            )
          );
          console.log(apiData.properties.envy.violations);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [matchData, demoBool]); // Include demoBool in the dependency array

  return (
    <div>
      <Container>
        <Row>
          <h1>
            Solution Details{" "}
            <Button onClick={goBack} variant="outline-secondary">
              <FaArrowLeft /> Go Back
            </Button>{" "}
          </h1>
          {/* <Col>
                        <Table>
                            <tbody key={"tableBody"}>
                                {matchData.properties.map((object, i) => {
                                    let objectStr = JSON.stringify(object);
                                    let firstKey = Object.keys(object);
                                    let show = Object.values(object);
                                    return (
                                        <tr key={firstKey + "list"}>
                                            <td key={i}>{firstKey}</td>
                                            {show[0] ? (
                                                <td key={firstKey}>
                                                    <FaCheck />
                                                </td>
                                            ) : (
                                                <td key={firstKey + "check"}></td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <p>{matchData.explanation}</p>
                    </Col> */}
          <p></p>
        </Row>
        {/* <Row style={{ margin: 0 }}>
          <Col xs={4}>
            {typeof matchData === "undefined" ? (
              <p>Loading...</p>
            ) : (
              <Card>
                <Card.Body>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <OptionSwitch
                      name="View Solution"
                      tooltip="kk"
                      state={showMatch}
                      setState={setShowMatch}
                    />
                    <OptionSwitch
                      name="View Blocking Pairs"
                      tooltip="kk"
                      state={showBP}
                      setState={setShowBP}
                    />
                    <OptionSwitch
                      name="View Exchange Cycles"
                      tooltip="kk"
                      state={showEnvy}
                      setState={setShowEnvy}
                    />
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col>
            {typeof matchData === "undefined" ? (
              <p>Loading...</p>
            ) : (
              <Card>
                <Card.Body>k</Card.Body>
              </Card>
            )}
          </Col>
        </Row> */}
        <Row>
          <Col xs={3}>
            {typeof matchData === "undefined" ? (
              <p>Loading...</p>
            ) : (
              <Card>
                <Card.Body>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <OptionSwitch
                      name="View Solution"
                      tooltip="The solution is comprised of lines connecting various participants. If two partipants are connected, they are partnered to each other."
                      state={showMatch}
                      setState={setShowMatch}
                    />
                    <OptionSwitch
                      name="View Strict-Strict Blocking Pairs"
                      tooltip="A participant is in a weakly stable pair if there aren't any participants in the pair which mutually favor another participant over their current partner."
                      state={showBP}
                      setState={setShowBP}
                    />
                    <OptionSwitch
                      name="View Strict-Weak Blocking Pairs"
                      tooltip="A participant is in a strongly stable pair if there aren't any participants in the pair in which one participant strictly prefers another partner and that partner weakly prefers the participant over their current partner. This is a stronger measure of stability."
                      state={showStrongBP}
                      setState={setShowStrongBP}
                    />
                    <OptionSwitch
                      name="View Weak-Weak Blocking Pairs"
                      tooltip="A participant is in a super stable pair if there aren't any partners in the pair in which one partner and another participant prefer each other just as much as their current partners. This is the strongest measure of stability."
                      state={showSuperBP}
                      setState={setShowSuperBP}
                    />
                    <OptionSwitch
                      name="View Exchange Cycles"
                      tooltip="If A group of participants can exchange partners with each other and mutually recieve more-favored partners, they comprise an exchange cycle."
                      state={showEnvy}
                      setState={setShowEnvy}
                    />
                    <br />
                    <Pie options={options} data={pieData} height={7000} />
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col xs={9}>
            {typeof matchData === "undefined" ? (
              <p>Loading...</p>
            ) : (
              <>
                <div style={{ width: "100%", height: "600px" }}>
                  {matchData.metaData[2] <= 20 ||
                  matchData.metaData[2] <= 20 ? (
                    <Flow2
                      matchData={matchData}
                      properties={data.properties}
                      bp={showBP}
                      strongbp={showStrongBP}
                      superbp={showSuperBP}
                      envy={showEnvy}
                      match={showMatch}
                      envyVis={envyVis}
                      setEnvyVis={wrapperSetParentState}
                      envyInfo={envyInfo}
                      setEnvyInfo={setEnvyInfo}
                      selectInfo={selectInfo}
                      setSelectInfo={wrapperSetSelectState}
                    />
                  ) : (
                    <LargeInstanceFlow
                      matchData={matchData}
                      properties={data.properties}
                      bp={showBP}
                      // strongbp={showStrongBP}
                      // superbp={showSuperBP}
                      envy={showEnvy}
                      match={showMatch}
                      envyVis={envyVis}
                      setEnvyVis={wrapperSetParentState}
                      envyInfo={envyInfo}
                      setEnvyInfo={setEnvyInfo}
                      selectInfo={selectInfo}
                      setSelectInfo={wrapperSetSelectState}
                    />
                  )}
                </div>
                <br />
                <Row>
                  <Col>
                    <Table>
                      <tbody key={"tableBody"}>
                        <tr key="unstable">
                          <td key={0}>Unstable Matches</td>
                          {typeof data === "undefined" ? (
                            <p>Loading...</p>
                          ) : (
                            <td key={2}>
                              {
                                data.properties?.blockingpairs
                                  ?.blockingPairCount
                              }
                            </td>
                          )}
                        </tr>
                        <tr key="envious">
                          <td key={0}>Envious Agents</td>
                          {typeof data === "undefined" ? (
                            <p>Loading...</p>
                          ) : (
                            <td key={2}>
                              {data.properties?.envy?.numberViolators}
                            </td>
                          )}
                        </tr>
                        <tr key="exchange">
                          <td key={0}>Exchange Cycles</td>
                          {typeof data === "undefined" ? (
                            <p>Loading...</p>
                          ) : (
                            <td key={2}>
                              {data.properties?.po?.cycleList.length}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    {/* <p>{matchData.explanation}</p> */}
                    <p>{generateExplanation()}</p>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default MatchDetail2024;
